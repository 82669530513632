import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { UrlService } from 'app/services/url.service';
import { NewsService } from 'app/services/news.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import * as _rollupMoment from 'moment';
declare var $: any;


const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-allnews',
  templateUrl: './allnews.component.html',
  styleUrls: ['./allnews.component.scss']
})
export class AllnewsComponent implements OnInit {
  test : Date = new Date();
  page = 4;
  page1 = 5;
  focus;
  focus1;
  focus2;
  loading;
  news=[];
  mainews=[];
  activenews=[];
  settings;
 _imgUrl;
 gallery=[];
 next_page_url;
 prev_page_url;
 currentpage;
 last_page;
 type=0;
  date: {year: number, month: number};
  constructor(private renderer : Renderer2,private route: ActivatedRoute,private router: Router ,private _getallNews:NewsService,private urlapi :UrlService) {
    this._imgUrl=urlapi.imgUrl;
 }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => { 
      if(+params.get('foo') != undefined && +params.get('foo') != null ){
      this.type=+params.get('foo');
      this.getactivnews(this.type);
    }else{
      this.getactivnews(0);
    }
      });
   
  }
  getactivnews(t){
    this.loading=1;
    let limit = 24;
  /*   console.log('t',this.type); */
    this._getallNews.getNewsData(limit,1,t)
    .subscribe(data => {this.news = data.data.data,this.loading=0/* , console.log(data.data) */,this.last_page= data.data.last_page, this.currentpage=data.data.current_page,this.next_page_url=data.data.next_page_url,this.prev_page_url=data.data.prev_page_url},
    error => {
      if (error instanceof HttpErrorResponse){
        if(error.status === 401){
      this.router.navigate(['/home']) }
      else if(error.status === 0){
        this.loading=0;
      }
     /*  console.log(error); */
    }
    });
  }

  getDate(Cdate:string):string{
    var currentdate = new Date();
    var date =  new Date(Cdate).toLocaleDateString('en-GB');
    return date;
  }

  showDate(Cdate:string):string{
    var currentdate = new Date();
    var date =  new Date(Cdate).toLocaleString();
    return date;
  }

  formatutc(date_obb){
    let date_ob = new Date(date_obb);

// adjust 0 before single digit date
let date = ("0" + date_ob.getDate()).slice(-2);

// current month
let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

// current year
let year = date_ob.getFullYear();

// current hours
let hours = date_ob.getHours();

// current minutes
let minutes = date_ob.getMinutes();

// current seconds
let seconds = date_ob.getSeconds();

// prints date & time in YYYY-MM-DD HH:MM:SS format
return(year + "-" + month + "-" + date );/* + " " + hours + ":" + minutes + ":" + seconds */
  }

  itemDetails(item){
    this._getallNews.setItem(item);
    let currentUrl = this.router.url;
    
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate(['/blog-detail'])/* .then(() => { 
        window.location.reload(); }); */
      });
   
   }

   loadNext(){
    this.loading=1;
     if(this.next_page_url != null){
    let limit = 24;
/*     console.log('t',this.type); */
    this._getallNews.getNewsData(limit,this.currentpage+1,this.type)
    .subscribe(data => {this.news =this.news = data.data.data,this.loading=0,this.last_page= data.data.last_page,this.prev_page_url=data.data.prev_page_url/* ,console.log(data.data,this.currentpage+1,this.type) */,this.currentpage=data.data.current_page,this.next_page_url=data.data.next_page_url},
    error => {
      if (error instanceof HttpErrorResponse){
        if(error.status === 401){
      this.router.navigate(['/home']) }
      else if(error.status === 0){
        this.loading=0;
      }
     /*  console.log(error); */
    }
    });
   }
  }

  loadPrev(){
    this.loading=1;
     if(this.prev_page_url != null){
    let limit = 24;
/*     console.log('t',this.type); */
    this._getallNews.getNewsData(limit,this.currentpage-1,this.type)
    .subscribe(data => {this.news =this.news = data.data.data,this.loading=0,this.last_page= data.data.last_page,this.prev_page_url=data.data.prev_page_url/* ,console.log(data.data,this.currentpage+1,this.type) */,this.currentpage=data.data.current_page,this.next_page_url=data.data.next_page_url},
    error => {
      if (error instanceof HttpErrorResponse){
        if(error.status === 401){
      this.router.navigate(['/home']) }
      else if(error.status === 0){
        this.loading=0;
      }
     /*  console.log(error); */
    }
    });
   }
  }
}
