<!-- <div class="page-header" data-parallax="true" style="background-image: url('assets/img/industry.jpg');">
    <div class="filter"></div>
    <div class="container">
     <div class="motto text-center">
            <h3>أعضاء مجلس الإدارة</h3>
            <br />
        </div>  
    </div>
</div>
<main id="main">
<section id="blog-details" class="blog-details mt-0">
<div class="container-fluid p-5 text-center">
<div class="page-wrapper text-center">
    <div class="container text-center"> 
        <nav class="nav">
			<ul>
				<li>
					<a href="#"> <div class="member text-center" data-aos="fade-up" data-aos-delay="100">
						<div class="pic text-center mb-2"><img src="assets/img/team/1.jpg" class="img-fluid rounded img-thumbnail" alt=""></div>
						<div class="member-info">
						  <h4>السيد مازن حماد</h4>
						  <span>رئيس مجلس الإدارة</span>
						</div>
					  </div></a>
					<ul>
						<li>
							<a href="#"><div class="member text-center" data-aos="fade-up" data-aos-delay="100">
								<div class="pic text-center mb-2"><img src="assets/img/team/team-1.jpg" class="img-fluid rounded img-thumbnail" alt=""></div>
								<div class="member-info">
								  <h4>اسم تجريبي</h4>
								  <span>نائب رئيس مجلس الإدارة</span>
								</div>
							  </div></a>
							<ul>
								<li>
									<a href="#"><div class="member text-center" data-aos="fade-up" data-aos-delay="100">
										<div class="pic text-center mb-2"><img src="assets/img/team/team-1.jpg" class="img-fluid rounded img-thumbnail" alt=""></div>
										<div class="member-info">
										  <h4>اسم تجريبي</h4>
										  <span>نائب رئيس مجلس الإدارة</span>
										</div>
									  </div></a>
									<ul>
										<li>
											<a href="#"><div class="member text-center" data-aos="fade-up" data-aos-delay="100">
												<div class="pic text-center mb-2"><img src="assets/img/team/team-1.jpg" class="img-fluid rounded img-thumbnail" alt=""></div>
												<div class="member-info">
												  <h4>اسم تجريبي</h4>
												  <span>نائب رئيس مجلس الإدارة</span>
												</div>
											  </div></a>
											<ul>
												<li>
													<a href="#"><div class="member text-center" data-aos="fade-up" data-aos-delay="100">
														<div class="pic text-center mb-2"><img src="assets/img/team/team-1.jpg" class="img-fluid rounded img-thumbnail" alt=""></div>
														<div class="member-info">
														  <h4>اسم تجريبي</h4>
														  <span>نائب رئيس مجلس الإدارة</span>
														</div>
													  </div></a>
													<ul>
														<li>
															<a href="#"><div class="member text-center" data-aos="fade-up" data-aos-delay="100">
																<div class="pic text-center mb-2"><img src="assets/img/team/team-1.jpg" class="img-fluid rounded img-thumbnail" alt=""></div>
																<div class="member-info">
																  <h4>اسم تجريبي</h4>
																  <span>نائب رئيس مجلس الإدارة</span>
																</div>
															  </div></a>
														</li>
													</ul>
												</li>
											</ul>
										</li>
									</ul>
								</li>
								<li>
									<a href="#"><div class="member text-center" data-aos="fade-up" data-aos-delay="100">
										<div class="pic text-center mb-2"><img src="assets/img/team/team-1.jpg" class="img-fluid rounded img-thumbnail" alt=""></div>
										<div class="member-info">
										  <h4>اسم تجريبي</h4>
										  <span>نائب رئيس مجلس الإدارة</span>
										</div>
									  </div></a>
									<ul>
										<li>
											<a href="#"><div class="member text-center" data-aos="fade-up" data-aos-delay="100">
												<div class="pic text-center mb-2"><img src="assets/img/team/team-1.jpg" class="img-fluid rounded img-thumbnail" alt=""></div>
												<div class="member-info">
												  <h4>اسم تجريبي</h4>
												  <span>نائب رئيس مجلس الإدارة</span>
												</div>
											  </div></a>
											<ul>
												<li>
													<a href="#"><div class="member text-center" data-aos="fade-up" data-aos-delay="100">
														<div class="pic text-center mb-2"><img src="assets/img/team/team-1.jpg" class="img-fluid rounded img-thumbnail" alt=""></div>
														<div class="member-info">
														  <h4>اسم تجريبي</h4>
														  <span>نائب رئيس مجلس الإدارة</span>
														</div>
													  </div></a>
												</li>
											</ul>
										</li>
									</ul>
								</li>
							</ul>
						</li>
						<li>
							<a href="#"><div class="member text-center" data-aos="fade-up" data-aos-delay="100">
								<div class="pic text-center mb-2"><img src="assets/img/team/team-1.jpg" class="img-fluid rounded img-thumbnail" alt=""></div>
								<div class="member-info">
								  <h4>اسم تجريبي</h4>
								  <span>نائب رئيس مجلس الإدارة</span>
								</div>
							  </div></a>
							<ul>
								<li>
									<a href="#"><div class="member text-center" data-aos="fade-up" data-aos-delay="100">
										<div class="pic text-center mb-2"><img src="assets/img/team/team-1.jpg" class="img-fluid rounded img-thumbnail" alt=""></div>
										<div class="member-info">
										  <h4>اسم تجريبي</h4>
										  <span>نائب رئيس مجلس الإدارة</span>
										</div>
									  </div></a>
									<ul>
										<li>
											<a href="#"><div class="member text-center" data-aos="fade-up" data-aos-delay="100">
												<div class="pic text-center mb-2"><img src="assets/img/team/team-1.jpg" class="img-fluid rounded img-thumbnail" alt=""></div>
												<div class="member-info">
												  <h4>اسم تجريبي</h4>
												  <span>نائب رئيس مجلس الإدارة</span>
												</div>
											  </div></a>
										</li>
										<li>
											<a href="#"><div class="member text-center" data-aos="fade-up" data-aos-delay="100">
												<div class="pic text-center mb-2"><img src="assets/img/team/team-1.jpg" class="img-fluid rounded img-thumbnail" alt=""></div>
												<div class="member-info">
												  <h4>اسم تجريبي</h4>
												  <span>نائب رئيس مجلس الإدارة</span>
												</div>
											  </div></a>
										</li>
									</ul>
								</li>
							</ul>
						</li>
						<li>
							<a href="#"><div class="member text-center" data-aos="fade-up" data-aos-delay="100">
								<div class="pic text-center mb-2"><img src="assets/img/team/team-1.jpg" class="img-fluid rounded img-thumbnail" alt=""></div>
								<div class="member-info">
								  <h4>اسم تجريبي</h4>
								  <span>نائب رئيس مجلس الإدارة</span>
								</div>
							  </div></a>
							<ul>
								<li>
									<a href="#"><div class="member text-center" data-aos="fade-up" data-aos-delay="100">
										<div class="pic text-center mb-2"><img src="assets/img/team/team-1.jpg" class="img-fluid rounded img-thumbnail" alt=""></div>
										<div class="member-info">
										  <h4>اسم تجريبي</h4>
										  <span>نائب رئيس مجلس الإدارة</span>
										</div>
									  </div></a>
									
								</li>
								<li>
									<a href="#"><div class="member text-center" data-aos="fade-up" data-aos-delay="100">
										<div class="pic text-center mb-2"><img src="assets/img/team/team-1.jpg" class="img-fluid rounded img-thumbnail" alt=""></div>
										<div class="member-info">
										  <h4>اسم تجريبي</h4>
										  <span>نائب رئيس مجلس الإدارة</span>
										</div>
									  </div></a>
								</li>
							</ul>
						</li>
					</ul>
				</li>
			</ul>
		</nav>
    </div>
</div>
</div>
</section>
</main>
 -->

<div class="page-header" data-parallax="true" style="background-image: url('assets/img/industry.jpg');">
    <div class="filter"></div>
    <div class="container">
     <div class="motto text-center">
            <h3>أعضاء مجلس الإدارة</h3>
            <br />
        </div>  
    </div>
</div>
<main id="main">
<section id="blog-details" class="blog-details mt-0">
<div class="container-fluid p-5 text-center">
<div class="page-wrapper text-center">
    <div class="container text-center">
		<div class="row d-flex text-center">
			<div [class]="i==0?'col-md-12':'col-md-3'" class="p-5 m-auto" *ngFor="let member of members ;let i = index" [attr.data-index]="i">
				<div class="pic text-center mb-2"><img src="{{_imgUrl}}{{member.media[0].id}}/{{member.media[0].file_name}}" class="img-fluid rounded img-thumbnail" alt=""></div>
				<div class="member-info mt-3">
					<h4>{{member['value']}}</h4>
					<span>{{member['value2']}}</span>
				</div>
			  </div>
			</div>
		</div>
       <!--  <nav class="nav">
			<ul>
				<li >
					<a > <div class="member text-center" data-aos="fade-up" data-aos-delay="100">
						<div class="pic text-center mb-2"><img src="{{_imgUrl}}{{members[0].media[0].id}}/{{members[0].media[0].file_name}}" class="img-fluid rounded img-thumbnail" alt=""></div>
						<div class="member-info">
						  <h4>{{members[0]['value']}}</h4>
						  <span>{{members[0]['value1']}}</span>
						</div>
					  </div></a>
					  <div *ngFor="let member of members ;let i = index" [attr.data-index]="i">
					<ul *ngIf="i%4 == 0">
						<li *ngFor="let member of members ;let d = i" [attr.data-index]="d">
							<a *ngIf="d < i+3"><div class="member text-center" data-aos="fade-up" data-aos-delay="100">
								<div class="pic text-center mb-2"><img src="{{_imgUrl}}{{member[d].media[0].id}}/{{member[d].media[0].file_name}}" class="img-fluid rounded img-thumbnail" alt=""></div>
								<div class="member-info">
									<h4>{{member[d]['value']}}</h4>
									<span>{{member[d]['value1']}}</span>
								</div>
							  </div></a>
						</li>
					</ul>
				</div>
				</li>
			</ul>
		</nav> -->
    </div>
</div>
</section>
</main> 
