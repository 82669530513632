<div class="page-header" data-parallax="true" style="background-image: url('assets/img/industry.jpg');">
    <div class="filter"></div>
    <div class="container">
     <div class="motto text-center">
            <h2>كل الأخبار</h2>
            <br />
        </div>  
    </div>
</div>
<main id="main">
<section id="allnews" class="allnews mt-0">
    <div class="container-fluid p-2">
        <div class="row text-center m-5">
          <div class="col-md-3 p-2 text-right" *ngFor="let news of news ;let j = index" [attr.data-index]="j">
            <div class="card p-3">
              <a (click)="itemDetails(news)">
              <img class="card-img-top" src="{{_imgUrl}}{{news.media[0].id}}/{{news.media[0].file_name}}" alt="Card image cap">
              <div class="card-body m-2 p-2">
                <h3 class="card-title">{{news.title}}</h3>
                <p class="card-text" [innerHTML]="news.content">
                </p>
                <p class="card-text"><small class="text-muted"><i class="fa fa-eye"></i>{{news.seenCount}}<i class="fa fa-user"></i>{{news.user.username}}<i class="fa fa-calendar"></i>{{formatutc(news.created_at)}}</small></p>
            </div>
              </a>
            </div>
          </div>
        <!--   <div class="col-md-4 col-lg-4">
            <div class="card">
              <a href="#">
              <img class="card-img-top" src="assets/img/gallery/IMG-20210316-WA0044.jpg" alt="Card image cap">
              <div class="card-body">
                <h5 class="card-title">Lorem ipsum dolor sit amet.</h5>
                <p class="card-text">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab accusantium ad alias, aliquid amet aspernatur atque culpa cum debitis dicta doloremque, dolorum ea eos et excepturi explicabo facilis harum illo impedit incidunt laborum laudantium...
                </p>
                <p class="card-text"><small class="text-muted"><i class="fa fa-eye"></i>1000<i class="fa fa-user"></i>admin<i class="fa fa-calendar"></i>Jan 20, 2018</small></p>
            </div>
              </a>
            </div>
          </div> -->
          <div class="col-md-12 text-center spinner" *ngIf="loading==1"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
          
         <!--  <div class="col-md-12 text-center col-lg-12">
            <button class="btn btn-4" *ngIf="next_page_url != null" (click)="loadmorenews()">عرض المزيد</button>
          </div> -->
        </div>
        <div class="container">
            <div class="row text-center mt-5">
              <nav aria-label="col-md-12 Page navigation example" style="margin:auto;">
                <ul class="pagination d-flex">
                  
                  <li class="page-item m-auto p-1"><button class="page-link  btn-n" (click)="loadPrev()" [disabled]="prev_page_url == null" > <i class="fa fa-caret-right"></i></button></li>
                  <li class="page-item m-auto p-1"><p style="direction:rtl; margin:0px 5px; vertical-align: middle;"> صفحة {{currentpage}} من {{last_page}}</p></li>
                  <li class="page-item m-auto p-1"><button class="page-link  btn-n"  (click)="loadNext()" [disabled]="next_page_url == null"> <i class="fa fa-caret-left"></i></button></li>
                 
                  
                </ul>
              </nav></div>
        </div>
        </div>

</section>
</main>