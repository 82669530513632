<div class="page-header" data-parallax="true" style="background-image: url('assets/img/industry.jpg');">
    <div class="filter"></div>
    <div class="container">
     <div class="motto text-center">
            <h2>الحساب الشخصي</h2>
            <br />
        </div>  
    </div>
</div>
<main id="main" >
<section id="allnews" class="allnews mt-5" *ngIf="mydata">
    <div class="container-fluid p-2">
        <div class=" text-center"><!-- row -->
            <div class="col-md-10 m-auto text-right">
                <div class="content table-responsive table-full-width text-center">
                <table class="table table-hover table-striped">
                    <thead>
                        <tr>
                            <th>الاسم الثلاثي</th>
                            <th>المدينة</th>
                            <th>تاريخ الانتساب</th>
                            <th>رقم السجل التجاري</th>
                            <th>الدرجة</th>
                            <th>العنوان</th>
                            <th>رقم الهاتف</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{mydata.name}}</td>
                            <td>{{mydata.city}}</td>
                            <td>{{mydata.hire_date}}</td>
                            <td>{{mydata.record_no}}</td>
                            <td>{{mydata.degree}}</td>
                            <td>{{mydata.address}}</td>
                            <td>{{mydata.phones[0].phone_number}}</td>
                        </tr>
                    </tbody> 
                  
              </table>
            </div>
            <h4 class="mt-5">نوع المنشأة التجارية :</h4><p>{{mydata.trade_type}}</p>
                   <p></p>
                   <p><b>آخر دفعة:</b> &nbsp;<span>{{lastpay}}</span> ل.س</p>
                   <p><b>آخر سنة تسديد:</b>&nbsp; <span>{{mydata.years[0].year}}</span></p>
        </div> </div>
        </div>
        </section>
        </main>