import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule,Routes  } from '@angular/router';
import { AppRoutingModule } from './app.routing';

import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';

import { ComponentsModule } from './components/components.module';
/* import { ExamplesModule } from './examples/examples.module'; */
import { HeaderComponent } from './shared/header/header.component';
import { BottomFooterComponent } from './shared/bottom-footer/bottom-footer.component';
/* import { HomeComponent } from './home/home.component'; */
import { BlogViewComponent } from './blog-view/blog-view.component';/* 
import { SignupComponent } from './signup/signup.component'; */
import { LoginComponent } from './login/login.component';
import { CommpaperComponent } from './commpaper/commpaper.component';
import { MembersComponent } from './members/members.component';
import { AuthService } from './auth/auth.service';
import { AuthGuard } from './auth/auth.guard';
import { TokenInterceptorService } from './auth/token-interceptor.service';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { AllnewsComponent } from './allnews/allnews.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { GalleryModule } from 'ng-gallery';
import { AboutComponent } from './about/about.component';
import {LocationStrategy, Location, PathLocationStrategy} from '@angular/common';
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    HeaderComponent,
    BottomFooterComponent,
    /* HomeComponent, */
    BlogViewComponent,
   /*  SignupComponent, */
    LoginComponent,
    CommpaperComponent,
    MembersComponent,
    AllnewsComponent,
    MyAccountComponent,
    AboutComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    ComponentsModule,
    GalleryModule,
   /*  ExamplesModule, */
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CarouselModule 
  ],
  providers: [    
    AuthService,AuthGuard,{
    provide:HTTP_INTERCEPTORS,
    useClass:TokenInterceptorService,
    multi:true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
