<!-- <app-navbar></app-navbar>
<router-outlet></router-outlet>
<app-footer *ngIf="removeFooter()"></app-footer>
 -->

  <!-- ======= Hero Section ======= -->
  <section id="hero">
    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="assets/img/unnamed-29.jpg" class="d-block w-100" alt="...">
        </div>
        <div class="carousel-item">
          <img src="assets/img/EoTaVipU4AAEnar.jpg" class="d-block w-100" alt="...">
        </div>
        <div class="carousel-item">
          <img src="assets/img/160966086935.jpg" class="d-block w-100" alt="...">
        </div>
      </div>
      <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
    <div class="hero-container" data-aos="fade-up" data-aos-delay="150">
      <h1>غرفة تجارة و صناعة طرطوس</h1>
      <h5>اطلع على آخر أخبار الغرفة  و الأحداث الهامة و تفاصيل اخرى</h5>
      <div class="d-flex">
        <a  (click)="loadmorenews()" class="btn-get-started scrollto mt-5">اطلع على المزيد</a>
        <!-- <a href="#" class="venobox btn-watch-video" data-vbtype="video" data-autoplay="true">شاهد<i class="icofont-play-alt-2"></i></a> -->
      </div>
    </div>
  </section><!-- End Hero -->

  <main id="main">
    <!-- ======= About Section ======= -->
    <section id="about" class="about">
      <div class="container" data-aos="fade-up">

        <div class="row justify-content-end">
          <div class="col-lg-11">
            <div class="row justify-content-end">

              <div class="col-lg-3 col-md-5 col-6 d-md-flex align-items-md-stretch">
                <div class="count-box">
                  <i class="icofont-simple-smile"></i>
                  <span data-toggle="counter-up">{{settings?settings.countPersons:''}}</span>
                  <p>عدد التجار الكلي</p>
                </div>
              </div>

              <div class="col-lg-3 col-md-5 col-6 d-md-flex align-items-md-stretch">
                <div class="count-box">
                  <i class="icofont-document-folder"></i>
                  <span data-toggle="counter-up">{{settings?settings.countCompanies:''}}</span>
                  <p>عدد الشركات الكلي</p>
                </div>
              </div>

              <div class="col-lg-3 col-md-5 col-6 d-md-flex align-items-md-stretch">
                <div class="count-box">
                  <i class="icofont-clock-time"></i>
                  <span data-toggle="counter-up">{{settings?settings.newPersons:''}}</span>
                  <p>التجار المنتسبين لهذا العام</p>
                </div>
              </div>

              <div class="col-lg-3 col-md-5 col-6 d-md-flex align-items-md-stretch">
                <div class="count-box">
                  <i class="icofont-award"></i>
                  <span data-toggle="counter-up">{{settings?settings.newCompanies:''}}</span>
                  <p>الشركات المنتسبة لهذا العام</p>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="row">

          <div class="col-lg-6  align-self-baseline" data-aos="zoom-in" data-aos-delay="100">
            <img src="assets/img/gallery/555.jpg" class="img-fluid" alt="">
            <!-- <a href="https://youtu.be/WXH882dN5yo" class="venobox play-btn mb-4" data-vbtype="video" data-autoplay="true"></a> -->
          </div>

          <div class="col-lg-6 pt-3 pt-lg-0 content" *ngIf="gensettings != undefined">
            <h3 class="mb-3">من نحن :</h3>
            <p>
              {{gensettings[5] != undefined?gensettings[5]['value'] :''}}<br>
            </p>
           <!--  <ul>
              <li><i class="bx bx-check-double"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
              <li><i class="bx bx-check-double"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
              <li><i class="bx bx-check-double"></i> Voluptate repellendus pariatur reprehenderit corporis sint.</li>
              <li><i class="bx bx-check-double"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.</li>
            </ul> -->
            <h4 class="mb-3">رؤيتنا</h4>
            <p>
              {{gensettings[6] != undefined?gensettings[6]['value'] :''}}
            </p>
          </div>

        </div>

      </div>
    </section><!-- End About Section -->
<!-- ============= latest news ============= -->
<section id="latestnews" class="latestnews">
  <div class="container container-title">
    <div class="section-title">
      <h2>آخر الأحداث الهامة</h2>
      <p>اطلع على آخر أخبار الغرفة</p>
    </div>
  </div>
  <div class="container-fluid" data-aos="fade-up">
    <owl-carousel-o [options]="customOptions">
      <ng-container *ngFor="let mainews of mainews">
        <ng-template class="slide" carouselSlide [id]="mainews.id">
      <div class="latestnews-item" >
        <div class="container mt-5">
          <div class="row">
            <div class="col-12">
              <article class="blog-card">
                <div class="blog-card__background">
                  <div class="card__background--wrapper">
                    <div class="card__background--main" [style.background]="'url('+ _imgUrl+ mainews.media[0].id + '/'+mainews.media[0].file_name+')'"><!-- style="background-image: url('assets/img/gallery/22.jpg');" -->
                      <div class="card__background--layer"></div>
                    </div>
                  </div>
                </div>
                <div class="blog-card__head">
                  <span class="date__box">
                    <span class="date__day">{{formatutc(mainews.created_at)}}</span>
                   <!--  <span class="date__month">تموز</span> -->
                  </span>
                </div>
                <div class="blog-card__info">
                  <h5>{{mainews.title}}</h5>
                  <p class="blog-card__infop"> 
                    <a  class="icon-link " style="direction:rtl;"><i class="fa fa-eye p-2"></i>{{mainews.seenCount}}</a>
                    <a  class="icon-link mr-3 ml-2"><i class="fa fa-pencil-square-o p-1"></i>{{mainews.user.username}}</a>
                   
                  </p>
                  <p class="cardimp-text" [innerHTML]="mainews.content"></p>
                  <a  class="btn btn--with-icon" (click)="itemDetails(mainews)"><i class="btn-icon fa fa-long-arrow-right"></i>قراءة المزيد</a>
                </div>
              </article>
            </div>
          </div>
        </div>
        
        <section class="detail-page">
          <div class="container mt-5">
            
          </div>
        </section>
      </div></ng-template></ng-container>
    </owl-carousel-o>
    
<!--     <div class="owl-carousel testimonials-carousel">

    <div class="latestnews-item"  *ngFor="let mainews of mainews ;let i = index" [attr.data-index]="i">
      <div class="container mt-5">
        <div class="row">
          <div class="col-12">
            <article class="blog-card">
              <div class="blog-card__background">
                <div class="card__background--wrapper">
                  <div class="card__background--main" [style.background]="'url('+ _imgUrl+ mainews.media[0].id + '/'+mainews.media[0].file_name+')'"> --><!-- style="background-image: url('assets/img/gallery/22.jpg');" -->
                  <!--   <div class="card__background--layer"></div>
                  </div>
                </div>
              </div>
              <div class="blog-card__head">
                <span class="date__box">
                  <span class="date__day">{{formatutc(mainews.updated_at)}}</span> -->
                 <!--  <span class="date__month">تموز</span> -->
              <!--   </span>
              </div>
              <div class="blog-card__info">
                <h5>{{mainews.title}}</h5>
                <p class="blog-card__infop"> 
                  <a  class="icon-link " style="direction:rtl;"><i class="fa fa-eye p-2"></i>{{mainews.seenCount}}</a>
                  <a  class="icon-link mr-3 ml-2"><i class="fa fa-pencil-square-o p-1"></i>{{mainews.user.username}}</a>
                 
                </p>
                <p class="cardimp-text" [innerHTML]="mainews.content"></p>
                <a  class="btn btn--with-icon" (click)="itemDetails(mainews)"><i class="btn-icon fa fa-long-arrow-right"></i>قراءة المزيد</a>
              </div>
            </article>
          </div>
        </div>
      </div>
      
      <section class="detail-page">
        <div class="container mt-5">
          
        </div>
      </section> -->
  <!--   </div>

    </div> --></div></section>
<!-- ============end of latestnews============= -->
<section id="varnews" class="varnews" *ngIf="activenews">
  <div class="container container-title">
    <div class="section-title">
      <h2>نشاطات الغرفة</h2>
      <p>تابع أحدث نشاطاتنا</p>
    </div>
  </div>
  <div class="container varnews-container">
    <div class="row d-flex">
      <div class="col-lg-8 col-md-8 " data-aos="fade-up" data-aos-delay="100">
        <owl-carousel-o [options]="customOptions2">
          <ng-container *ngFor="let activenew of activenews">
            <ng-template class="slide" carouselSlide [id]="activenew.id">
              <div class="varnews-item" >
                <div class="blog-card1 spring-fever" [style.background]="'url('+ _imgUrl+ activenew.media[0].id + '/'+ activenew.media[0].file_name+') '">
                  <!-- /.title-content -->
                  <div class="card-info" (click)="itemDetails(activenew)">
                 اقرأ المزيد
                  </div><!-- /.card-info -->
                  <div class="utility-info">
                    <ul class="utility-list">
                      <li><h4><b>{{activenew.title}}</b></h4></li>
                    </ul>
                    <ul class="utility-list">
                      <li class="date">{{formatutc(activenew.created_at)}}</li>
                    </ul>
                  </div><!-- /.utility-info -->
                  <!-- overlays -->
                  <div class="gradient-overlay"></div>
                  <div class="color-overlay"></div>
                </div>
                </div>

            </ng-template></ng-container></owl-carousel-o>
     
      </div>
      <div class="col-lg-4 col-md-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
        <div class=" p-5 sidenews">
          <div class="row">
            <div class="col-lg-12 col-md-12 mb-2 align-items-stretch" *ngFor="let actisidenews of activenews ;let b= index" [attr.data-index]="b" data-aos="fade-up" data-aos-delay="100">
              <div class="card sidecard bg-dark text-white" (click)="itemDetails(actisidenews)">
                <img src="{{_imgUrl}}{{actisidenews.media[0].id}}/{{actisidenews.media[0].file_name}}" class="card-img" alt="...">
                <div class="card-img-overlay">
                  <h5 class="card-title">{{actisidenews.title}}</h5>
                  <p class="card-text" [innerHTML]="actisidenews.content"></p>
                 <!--  <p class="card-text">Last updated 3 mins ago</p> -->
                </div>
              </div>
            </div>
          </div>
    </div></div></div>
  </div>
</section>
<!-- ========== allnews =========== -->
<section id="allnews" class="allnews">
  <div class="container container-title">
    <div class="section-title">
      <h2>أخبار منوعة</h2>
      <p>تابع أخبار الغرفة و كل جديد</p>
    </div>
  </div>
<div class="container">
<div class="row">
  <div class="col-md-4 col-lg-4 " *ngFor="let news of news ;let j = index" [attr.data-index]="j">
    <div class="card">
      <a (click)="itemDetails(news)">
      <img class="card-img-top" src="{{_imgUrl}}{{news.media[0].id}}/{{news.media[0].file_name}}" alt="Card image cap">
      <div class="card-body">
        <h5 class="card-title">{{news.title}}</h5>
        <p class="cardimp-text" [innerHTML]="news.content">
          
        </p>
        <p class="card-text"><small class="text-muted"><i class="fa fa-eye"></i>{{news.seenCount}}<i class="fa fa-user"></i>{{news.user.username}}<i class="fa fa-calendar"></i>{{formatutc(news.created_at)}}</small></p>
    </div>
      </a>
    </div>
  </div>
<!--   <div class="col-md-4 col-lg-4">
    <div class="card">
      <a href="#">
      <img class="card-img-top" src="assets/img/gallery/IMG-20210316-WA0044.jpg" alt="Card image cap">
      <div class="card-body">
        <h5 class="card-title">Lorem ipsum dolor sit amet.</h5>
        <p class="card-text">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab accusantium ad alias, aliquid amet aspernatur atque culpa cum debitis dicta doloremque, dolorum ea eos et excepturi explicabo facilis harum illo impedit incidunt laborum laudantium...
        </p>
        <p class="card-text"><small class="text-muted"><i class="fa fa-eye"></i>1000<i class="fa fa-user"></i>admin<i class="fa fa-calendar"></i>Jan 20, 2018</small></p>
    </div>
      </a>
    </div>
  </div> -->
  <div class="col-md-12 text-center col-lg-12">
    <button class="btn btn-4" (click)="loadmorenews()">عرض المزيد</button>
  </div>
</div>
</div>
</section>
<!-- ====================== -->
    <!-- ======= About Boxes Section ======= -->
   <!--  <section id="about-boxes" class="about-boxes">
      <div class="container" data-aos="fade-up">

        <div class="row">
          <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
            <div class="card">
              <img src="assets/img/about-boxes-1.jpg" class="card-img-top" alt="...">
              <div class="card-icon">
                <i class="ri-brush-4-line"></i>
              </div>
              <div class="card-body">
                <h5 class="card-title"><a href="">Our Mission</a></h5>
                <p class="card-text">Lorem ipsum dolor sit amet, consectetur elit, sed do eiusmod tempor ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="card">
              <img src="assets/img/about-boxes-2.jpg" class="card-img-top" alt="...">
              <div class="card-icon">
                <i class="ri-calendar-check-line"></i>
              </div>
              <div class="card-body">
                <h5 class="card-title"><a href="">Our Plan</a></h5>
                <p class="card-text">Sed ut perspiciatis unde omnis iste natus error sit voluptatem doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
            <div class="card">
              <img src="assets/img/about-boxes-3.jpg" class="card-img-top" alt="...">
              <div class="card-icon">
                <i class="ri-movie-2-line"></i>
              </div>
              <div class="card-body">
                <h5 class="card-title"><a href="">Our Vision</a></h5>
                <p class="card-text">Nemo enim ipsam voluptatem quia voluptas sit aut odit aut fugit, sed quia magni dolores eos qui ratione voluptatem sequi nesciunt Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet. </p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section> --><!-- End About Boxes Section -->

 <!-- End Clients Section -->

    <!-- ======= Features Section ======= -->
  <!--   <section id="features" class="features">
      <div class="container container-title">
        <div class="section-title">
          <h2>حول الغرفة</h2>
          <p>معلومات عن غرفة تجارة و صناعة طرطوس</p>
        </div>
      </div>
      <div class="container" data-aos="fade-up">

        <ul class="nav nav-tabs row d-flex">
          <li class="nav-item col-3">
            <a class="nav-link active show" data-toggle="tab" href="#tab-1">
              <i class="ri-gps-line"></i>
              <h4 class="d-none d-lg-block ">هيكلية الغرفة</h4>
            </a>
          </li>
          <li class="nav-item col-3">
            <a class="nav-link" data-toggle="tab" href="#tab-2">
              <i class="ri-body-scan-line"></i>
              <h4 class="d-none d-lg-block ">فلسفة الغرفة</h4>
            </a>
          </li>
          <li class="nav-item col-3">
            <a class="nav-link" data-toggle="tab" href="#tab-3">
              <i class="ri-sun-line"></i>
              <h4 class="d-none d-lg-block ">اللجان التخصصية و القطاعية</h4>
            </a>
          </li>
          <li class="nav-item col-3">
            <a class="nav-link" data-toggle="tab" href="#tab-4">
              <i class="ri-store-line"></i>
              <h4 class="d-none d-lg-block ">المجالس</h4>
            </a>
          </li>
        </ul>

        <div class="tab-content">
          <div class="tab-pane active show" id="tab-1">
            <div class="row">
              <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                <h3 class="mb-3">تتألف هيكلية الغرفة من :</h3>
                <p class="font-italic">
                <b> الجسم الإداري</b>
                  تتلخص الجهات القيادية في غرفة صناعة طرطوس وريفها بالهيئة العامة ومجلس الإدارة ومكتب الغرفة. ويتلقى كل من مجلس الإدارة ومكتب الغرفة الدعم من عدد من اللجان المتخصصة التي تعمل بشكل متوازي معها في عدد من المواضيع الخاصة.
                   
                <br><br> <b>الهيئة العامة</b>
                  تتألف الهئية العامة وهي الجهة التمثيلية الرئيسة من 16710 عضو مسجل، وتجتمع مرة واحدة سنوياً في جلسة عادية بدعوة من مجلس الإدارة لمنافشة ميزانية الغرفة وحساباتها الختامية وتقريرها السنوي.
                   
                  <br><br> <b> مجلس الإدارة</b>
                  تقع على مجلس الإدارة مسؤولية إدارة شؤون الغرفة وهو مسؤول أمام الهئية العامة عن كامل نشاطاتها. ويتألف مجلس الإدارة من 18 عضواً تنتخب الهيئة العامة ثلثيهم، بينما يعين وزير الصناعة الثلث الباقي تعييناً مباشراً.
                  مكتب الغرفة
                  يعهد إلى مكتب الغرفة إدارة العمليات اليومية للغرفة وتنفيذ قرارات مجلس الإدارة ورفع التقارير إليه وتسيير البريد الوارد والصادر, ويتألف مكتب الغرفة من سبعة أعضاء ينتخبهم مجلس الإدارة وهم: رئيس الغرفة، ونائبي الرئيس وأمين السر والخازن وعضوي مكتب... 
                </p> -->
              <!--   <ul>
                  <li><i class="ri-check-double-line"></i> لجنة التنظيم والإدارة</li>
                  <li><i class="ri-check-double-line"></i> لجنة التسجيل والكفالات</li>
                  <li><i class="ri-check-double-line"></i>لجنة المباني والعقارات</li>
                </ul> -->
            <!--     <a class="btn btn-outline pl-5 pr-5">اقرأ المزيد ..</a> -->
               <!--  <p>
                  Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                  velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                  culpa qui officia deserunt mollit anim id est laborum
                </p> -->
              <!-- </div>
              <div class="col-lg-6 order-1 order-lg-2 text-center">
                <img src="assets/img/undraw_Business_decisions_re_84ag.svg" alt="" class="img-fluid w-100">
              </div>
            </div>
          </div>
          <div class="tab-pane" id="tab-2">
            <div class="row">
              <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                <h3 class="mb-3">فلسفتنا :</h3>
                <p class="font-italic">
                  دعم شعار صنع في سوريا
                  <br><br> <b>الرسالة</b>
                  "ليبقى الإرتقاء بالصناعة الوطنية من خلال الترويج لصناعتنا وتوجيهها نحو المزيد من التحسن والتقدم"
                  <br><br> <b>المهمة</b>
                  "إننا نهدف بغية تحقيق رسالتنا إلى تقديم خدمات متفوقة وإرشاد متميز لتحقيق الأهداف الرامية إلى تحسين صناعتنا وتطويرها. مهمتنا هي أن نكون المدافع عن مصالح أعضاء غرفتنا وصوتهم القوي، كما هي في خلق بيئة صحية للمنافسة والتحسين بما يساهم في ارتقاء اقتصادنا الجديد"
                </p>
                <ul>
                  <li class="d-flex"><i class="ri-check-double-line ml-2"></i>تمثل مصالح  الصناعيين الأعضاء وحمايتها</li>
                  <li class="d-flex"><i class="ri-check-double-line ml-2"></i> تزويد الصناعيين بوسائط تشبيك مناسبة</li>
                  <li class="d-flex"><i class="ri-check-double-line ml-2" ></i>تأمين خدمات المعلومات </li>
                  <li class="d-flex"><i class="ri-check-double-line ml-2"></i>تقديم خدمات التدريب والاستشارات للصناعيين </li>
                </ul>
                <a class="btn btn-outline pl-5 pr-5">اقرأ المزيد ..</a>
              </div>
              <div class="col-lg-6 order-1 order-lg-2 text-center">
                <img src="assets/img/undraw_active_options_8je6 (1).svg" alt="" class="img-fluid">
              </div>
            </div>
          </div>
          <div class="tab-pane" id="tab-3">
            <div class="row">
              <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
               <h3 class="mb-3">تتألف اللجان التخصصية و القطاعية من :</h3> -->
               <!--  <p>
                  Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                  velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                  culpa qui officia deserunt mollit anim id est laborum
                </p> -->
              <!--   <ul>
                  <li class="d-flex"><i class="ri-check-double-line ml-2"></i>اللجان التخصصية</li>
                  <li class="d-flex"><i class="ri-check-double-line ml-2"></i>اللجان القطاعية الفرعية</li>
                  <li class="d-flex"><i class="ri-check-double-line ml-2" ></i>تأمين خدمات المعلومات </li>
                  <li class="d-flex"><i class="ri-check-double-line ml-2"></i>اللجان القطاعية</li>
                </ul>
                <a class="btn btn-outline pl-5 pr-5">اقرأ المزيد ..</a> -->
               <!--  <p class="font-italic">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua.
                </p> -->
             <!--  </div>
              <div class="col-lg-6 order-1 order-lg-2 text-center">
                <img src="assets/img/undraw_coffee_break_h3uu (1).svg" alt="" class="img-fluid">
              </div>
            </div>
          </div>
          <div class="tab-pane" id="tab-4">
            <div class="row">
              <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                <h3 class="mb-3">المجالس</h3> -->
               <!--  <p>
                  Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                  velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                  culpa qui officia deserunt mollit anim id est laborum
                </p>
                <p class="font-italic">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua.
                </p> -->
               <!--  <ul>
                  <li class="d-flex"><i class="ri-check-double-line ml-2"></i>مجلس المناطق</li>
                  <li class="d-flex"><i class="ri-check-double-line ml-2"></i>مجلس المخضرمين</li>
                  <li class="d-flex"><i class="ri-check-double-line ml-2" ></i>مجلس الشباب الصناعي</li>
                  <li class="d-flex"><i class="ri-check-double-line ml-2"></i>المجلس الصناعي </li>
                </ul>
                <a class="btn btn-outline pl-5 pr-5">اقرأ المزيد ..</a>
              </div>
              <div class="col-lg-6 order-1 order-lg-2 text-center">
                <img src="assets/img/undraw_interview_rmcf (1).svg" alt="" class="img-fluid">
              </div>
            </div>
          </div>
        </div>

      </div>
    </section> --><!-- End Features Section -->

    <!-- ======= Services Section ======= -->
    <section id="services" class="services section-bg">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>خدماتنا</h2>
          <p>اطلع على خدمات الغرفة</p>
        </div>

        <div class="row" data-aos="fade-up" data-aos-delay="200">
          <div class="col-md-6" [class]="n>0 ? 'mt-4 mt-md-0' :''" *ngFor="let servsetting of servsettings ;let n = index" [attr.data-index]="n">
            <div class="icon-box">
              <i class="icofont-tasks-alt"></i>
              <!-- <h4><a href="#">{{servsettings}}</a></h4> -->
              <p *ngIf="servsetting != undefined">{{servsetting != undefined ?servsetting.value:''}}</p>
            </div>
          </div>

      <!--     <div class="col-md-6 mt-4 mt-md-0">
            <div class="icon-box">
              <i class="icofont-chart-bar-graph"></i>
              <h4><a href="#">شهادات المنشأ</a></h4>
              <p>تنطوي هذه الخدمة على منح الأعضاء الراغبين تصدير منتجاتهم بشهادات منشأ توضح معطيات منتجاتهم من ناحية المنشأ والكميات المتضمنة في كل شحنة..</p>
            </div>
          </div>
          <div class="col-md-6 mt-4 mt-md-0">
            <div class="icon-box">
              <i class="icofont-image"></i>
              <h4><a href="#">خدمات المعلومات</a></h4>
              <p>تقارير معلومات عن الصناعات والمنتجات المحلية والأدلة التجارية العالمية وخدمات الإنترنت والاشتراك بموقع الغرفة..</p>
            </div>
          </div>
          <div class="col-md-6 mt-4 mt-md-0">
            <div class="icon-box">
              <i class="icofont-settings"></i>
              <h4><a href="#">مركز تنمية الموارد البشرية</a></h4>
              <p>تدير غرفة صناعة دمشق وريفها، تماشياً مع الهدف الرامي إلى تنمية الموارد البشرية في سوريا، مركزاً لتنمية الموارد البشرية يهدف إلى تحسين القدرات التنافسية للصناعة السورية ..</p>
            </div>
          </div>
          <div class="col-md-6 mt-4 mt-md-0">
            <div class="icon-box">
              <i class="icofont-earth"></i>
              <h4><a href="#">مركز تطوير صناعة الألبسة والنسيج </a></h4>
              <p>يهدف مركز تطوير النسيج والملبوسات إلى تنمية الموارد البشرية العاملة في حقل صناعة النسيج من خلال التركيز على برامج التدريب الإداري والفني..</p>
            </div>
          </div>
          <div class="col-md-6 mt-4 mt-md-0">
            <div class="icon-box">
              <i class="icofont-tasks-alt"></i>
              <h4><a href="#">الخدمات الاستشارية الفنية للشركات </a></h4>
              <p>خدمات نظام كاد (CAD) التصميم بمعونة الحاسوب ..</p>
            </div>
          </div> -->
        </div>

      </div>
    </section><!-- End Services Section -->
    <!-- ======= Services Section ======= -->
    <section id="services" class="services section-bg">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>شروط الانساب للغرفة</h2>
          <p>ما هي شروط الانتساب لغرفة تجارة و صناعة طرطوس ؟</p>
        </div>

        <div class="row" data-aos="fade-up" data-aos-delay="200">
          <div class="col-md-6" [class]="n>0 ? 'mt-4 mt-md-0' :''" *ngFor="let condsetting of condsettings ;let n = index" [attr.data-index]="n">
            <div class="icon-box">
              <i class="fa fa-arrow-right"></i>
             <!--  <h3><a href="#"></a></h3> -->
              <h5 *ngIf="condsetting != undefined">{{condsetting != undefined?condsetting.value:''}}</h5>
            </div>
          </div>
        </div>

      </div>
    </section><!-- End Services Section -->
       <!-- ======= Clients Section ======= -->
   <section id="clients" class="clients">
    <div class="container" data-aos="zoom-in">
      <div class="container container-title">
        <div class="section-title">
          <h2>مواقع صديقة</h2>
          <p>مواقع صديقة لغرفة التجارة</p>
        </div>
      </div>
      <div class="row">
        <div class="slider">
          <div class="slide-track">
            <div *ngFor="let slide of slides" class="slide">
             <a target="_blank" href="{{slide.value}}"> <img src="{{_imgUrl}}{{slide.media[0].id}}/{{slide.media[0].file_name}}" height="100" width="100" alt="" /></a>
            </div>
          </div>
        </div>
<!-- 
        <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <img src="assets/img/clients/client-1.png" class="img-fluid" alt="">
        </div>

        <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <img src="assets/img/clients/client-2.png" class="img-fluid" alt="">
        </div>

        <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <img src="assets/img/clients/client-3.png" class="img-fluid" alt="">
        </div>

        <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <img src="assets/img/clients/client-4.png" class="img-fluid" alt="">
        </div>

        <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <img src="assets/img/clients/client-5.png" class="img-fluid" alt="">
        </div>

        <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <img src="assets/img/clients/client-6.png" class="img-fluid" alt="">
        </div> -->

      </div>

    </div>
    <hr>
  </section>
    <!-- ======= Testimonials Section ======= -->
<!--    <section id="testimonials" class="testimonials">
      <div class="container" data-aos="zoom-in">

        <div class="owl-carousel testimonials-carousel">

          <div class="testimonial-item">
            <img src="assets/img/testimonials/testimonials-1.jpg" class="testimonial-img" alt="">
            <h3>Saul Goodman</h3>
            <h4>Ceo &amp; Founder</h4>
            <p>
              <i class="bx bxs-quote-alt-left quote-icon-left"></i>
              Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.
              <i class="bx bxs-quote-alt-right quote-icon-right"></i>
            </p>
          </div>

          <div class="testimonial-item">
            <img src="assets/img/testimonials/testimonials-2.jpg" class="testimonial-img" alt="">
            <h3>Sara Wilsson</h3>
            <h4>Designer</h4>
            <p>
              <i class="bx bxs-quote-alt-left quote-icon-left"></i>
              Export tempor illum tamen malis malis eram quae irure esse labore quem cillum quid cillum eram malis quorum velit fore eram velit sunt aliqua noster fugiat irure amet legam anim culpa.
              <i class="bx bxs-quote-alt-right quote-icon-right"></i>
            </p>
          </div>

          <div class="testimonial-item">
            <img src="assets/img/testimonials/testimonials-3.jpg" class="testimonial-img" alt="">
            <h3>Jena Karlis</h3>
            <h4>Store Owner</h4>
            <p>
              <i class="bx bxs-quote-alt-left quote-icon-left"></i>
              Enim nisi quem export duis labore cillum quae magna enim sint quorum nulla quem veniam duis minim tempor labore quem eram duis noster aute amet eram fore quis sint minim.
              <i class="bx bxs-quote-alt-right quote-icon-right"></i>
            </p>
          </div>

          <div class="testimonial-item">
            <img src="assets/img/testimonials/testimonials-4.jpg" class="testimonial-img" alt="">
            <h3>Matt Brandon</h3>
            <h4>Freelancer</h4>
            <p>
              <i class="bx bxs-quote-alt-left quote-icon-left"></i>
              Fugiat enim eram quae cillum dolore dolor amet nulla culpa multos export minim fugiat minim velit minim dolor enim duis veniam ipsum anim magna sunt elit fore quem dolore labore illum veniam.
              <i class="bx bxs-quote-alt-right quote-icon-right"></i>
            </p>
          </div>

          <div class="testimonial-item">
            <img src="assets/img/testimonials/testimonials-5.jpg" class="testimonial-img" alt="">
            <h3>John Larson</h3>
            <h4>Entrepreneur</h4>
            <p>
              <i class="bx bxs-quote-alt-left quote-icon-left"></i>
              Quis quorum aliqua sint quem legam fore sunt eram irure aliqua veniam tempor noster veniam enim culpa labore duis sunt culpa nulla illum cillum fugiat legam esse veniam culpa fore nisi cillum quid.
              <i class="bx bxs-quote-alt-right quote-icon-right"></i>
            </p>
          </div>

        </div>

      </div>
    </section>  --><!-- End Testimonials Section -->


    <!-- ======= Portfolio Section ======= -->
<!--     <section id="portfolio" class="portfolio">
      <div class="container container-title">
        <div class="section-title">
          <h2>معرض النشاطات</h2>
          <p>اطلع على معرض الصور لنشاطات الغرفة</p>
        </div>
      </div>
      <div class="container" data-aos="fade-up" >

        <div class="row" data-aos="fade-up" data-aos-delay="100">
          <div class="col-lg-12 d-flex justify-content-center">
            <ul id="portfolio-flters">
              <li data-filter="*" class="filter-active">الكل</li>
              <li data-filter=".filter-news">أخبار</li>
              <li data-filter=".filter-act">فعاليات</li>
              <li data-filter=".filter-visit">زيارات</li>
              <li data-filter=".filter-conf">اجتماعات</li>
            </ul>
          </div>
        </div>

        <div class="row portfolio-container" data-aos="fade-up" data-aos-delay="200">
           <div *ngFor="let gallery of gallery">
          <div class="col-lg-4 col-md-6 portfolio-item" [class]="getclass(gallery.type)">
            <img src="{{_imgUrl}}{{gallery.media[0].id}}/{{gallery.media[0].file_name}}" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>{{gallery.title}}</h4>
              <p>{{getType(gallery.type)}}</p>
              <a href="{{_imgUrl}}{{gallery.media[0].id}}/{{gallery.media[0].file_name}}" data-gall="portfolioGallery" class="venobox preview-link" title="{{getType(gallery.type)}}"><i class="bx bx-plus"></i></a>
              <a href="#" class="details-link" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div></div></div>

      </div>
    </section> -->

<!--           <div class="col-lg-4 col-md-6 portfolio-item filter-web">
            <img src="assets/img/gallery/FB_IMG_1618426577543.jpg" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>Web 3</h4>
              <p>Web</p>
              <a href="assets/img/gallery/FB_IMG_1618426577543.jpg" data-gall="portfolioGallery" class="venobox preview-link" title="Web 3"><i class="bx bx-plus"></i></a>
              <a href="portfolio-details.html" class="details-link" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-app">
            <img src="assets/img/gallery/IMG-20210316-WA0044.jpg" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>App 2</h4>
              <p>App</p>
              <a href="assets/img/gallery/IMG-20210316-WA0044.jpg" data-gall="portfolioGallery" class="venobox preview-link" title="App 2"><i class="bx bx-plus"></i></a>
              <a href="portfolio-details.html" class="details-link" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-card">
            <img src="assets/img/gallery/IMG-20210316-WA0044.jpg" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>Card 2</h4>
              <p>Card</p>
              <a href="assets/img/portfolio/portfolio-4.jpg" data-gall="portfolioGallery" class="venobox preview-link" title="Card 2"><i class="bx bx-plus"></i></a>
              <a href="portfolio-details.html" class="details-link" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-web">
            <img src="assets/img/portfolio/portfolio-5.jpg" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>Web 2</h4>
              <p>Web</p>
              <a href="assets/img/portfolio/portfolio-5.jpg" data-gall="portfolioGallery" class="venobox preview-link" title="Web 2"><i class="bx bx-plus"></i></a>
              <a href="portfolio-details.html" class="details-link" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-app">
            <img src="assets/img/portfolio/portfolio-6.jpg" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>App 3</h4>
              <p>App</p>
              <a href="assets/img/portfolio/portfolio-6.jpg" data-gall="portfolioGallery" class="venobox preview-link" title="App 3"><i class="bx bx-plus"></i></a>
              <a href="portfolio-details.html" class="details-link" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-card">
            <img src="assets/img/portfolio/portfolio-7.jpg" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>Card 1</h4>
              <p>Card</p>
              <a href="assets/img/portfolio/portfolio-7.jpg" data-gall="portfolioGallery" class="venobox preview-link" title="Card 1"><i class="bx bx-plus"></i></a>
              <a href="portfolio-details.html" class="details-link" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-card">
            <img src="assets/img/portfolio/portfolio-8.jpg" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>Card 3</h4>
              <p>Card</p>
              <a href="assets/img/portfolio/portfolio-8.jpg" data-gall="portfolioGallery" class="venobox preview-link" title="Card 3"><i class="bx bx-plus"></i></a>
              <a href="portfolio-details.html" class="details-link" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-web">
            <img src="assets/img/portfolio/portfolio-9.jpg" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>Web 3</h4>
              <p>Web</p>
              <a href="assets/img/portfolio/portfolio-9.jpg" data-gall="portfolioGallery" class="venobox preview-link" title="Web 3"><i class="bx bx-plus"></i></a>
              <a href="portfolio-details.html" class="details-link" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div> -->

        <!-- End Portfolio Section -->

    <!-- ======= Team Section ======= -->
   <!--  <section id="team" class="team section-bg">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Team</h2>
          <p>Check our Team</p>
        </div>

        <div class="row">

          <div class="col-lg-4 col-md-6">
            <div class="member" data-aos="fade-up" data-aos-delay="100">
              <div class="pic"><img src="assets/img/team/team-1.jpg" class="img-fluid" alt=""></div>
              <div class="member-info">
                <h4>Walter White</h4>
                <span>Chief Executive Officer</span>
                <div class="social">
                  <a href=""><i class="icofont-twitter"></i></a>
                  <a href=""><i class="icofont-facebook"></i></a>
                  <a href=""><i class="icofont-instagram"></i></a>
                  <a href=""><i class="icofont-linkedin"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
            <div class="member">
              <div class="pic"><img src="assets/img/team/team-2.jpg" class="img-fluid" alt=""></div>
              <div class="member-info">
                <h4>Sarah Jhonson</h4>
                <span>Product Manager</span>
                <div class="social">
                  <a href=""><i class="icofont-twitter"></i></a>
                  <a href=""><i class="icofont-facebook"></i></a>
                  <a href=""><i class="icofont-instagram"></i></a>
                  <a href=""><i class="icofont-linkedin"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
            <div class="member">
              <div class="pic"><img src="assets/img/team/team-3.jpg" class="img-fluid" alt=""></div>
              <div class="member-info">
                <h4>William Anderson</h4>
                <span>CTO</span>
                <div class="social">
                  <a href=""><i class="icofont-twitter"></i></a>
                  <a href=""><i class="icofont-facebook"></i></a>
                  <a href=""><i class="icofont-instagram"></i></a>
                  <a href=""><i class="icofont-linkedin"></i></a>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section> --><!-- End Team Section -->

    <!-- ======= Contact Section ======= -->
    <section id="contact" class="contact">
      <div class="container" data-aos="fade-up">

    <div class="section-title">
        <h2>تواصل معنا</h2>
        <p>يمكنك التواصل معنا الآن</p>
      </div>

      <div class="row">

        <div class="col-lg-6">

          <div class="row">
            <div class="col-md-12">
              <div class="info-box">
                <i class="bx bx-map"></i>
                <h3>العنوان</h3>
                <p *ngIf="gensettings != undefined">{{gensettings[4] != undefined?gensettings[4]['value']:''}}</p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="info-box mt-4">
                <i class="bx bx-envelope"></i>
                <h3>ارسل رسالة</h3>
                <p *ngIf="gensettings != undefined">{{gensettings[0] != undefined?gensettings[0]['value']:''}}</p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="info-box mt-4">
                <i class="bx bx-phone-call"></i>
                <h3>اتصل بنا</h3>
                <p *ngIf="gensettings != undefined">{{gensettings[1] != undefined ?gensettings[1]['value']:''}}
                <!--   <br>+1 6678 254445 41</p> -->
              </div>
            </div>
          </div>

        </div>

        <div class="col-lg-6 mt-4 mt-lg-0">
          <form action="forms/contact.php" method="post" role="form" class="php-email-form">
            <div class="form-row">
              <div class="col-md-5 form-group">
                <input type="text" name="name" class="form-control" id="name" placeholder="الاسم" data-rule="minlen:4" data-msg="أدخل على الأقل أربعة محارف" />
                <div class="validate"></div>
              </div>
              <div class="col-md-5 form-group">
                <input type="email" class="form-control" name="email" id="email" placeholder="الايميل" data-rule="email" data-msg="أدخل عنوان بريدي صالح" />
                <div class="validate"></div>
              </div>
            </div>
            <div class="form-group">
              <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" data-rule="minlen:4" data-msg="أدخل على الأقل ثمانية محارف" />
              <div class="validate"></div>
            </div>
            <div class="form-group">
              <textarea class="form-control" name="message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message"></textarea>
              <div class="validate"></div>
            </div>
            <div class="mb-3">
              <div class="loading">Loading</div>
              <div class="error-message"></div>
              <div class="sent-message">تم إرسال الرسالة بنجاح ,شكراً لك</div>
            </div>
            <div class="text-center"><button type="submit">إرسال</button></div>
          </form>
        </div>

      </div>

      </div>
    </section><!-- End Contact Section -->

  </main><!-- End #main -->
  <a href="#" class="back-to-top"><i class="ri-arrow-up-line"></i></a>
<!--   <div id="preloader"></div> -->