import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { ComponentsComponent } from './components/components.component';
/* import { ProfileComponent } from './examples/profile/profile.component'; */
/* import { SignupComponent } from './signup/signup.component'; */
import { BlogViewComponent } from './blog-view/blog-view.component';
import { LoginComponent } from './login/login.component';
import { MembersComponent } from './members/members.component';
import { CommpaperComponent } from './commpaper/commpaper.component';
import { AllnewsComponent } from './allnews/allnews.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes =[
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home',             component: ComponentsComponent },
/*     { path: 'user-profile',     component: ProfileComponent }, */
 /*    { path: 'signup',           component: SignupComponent }, */
    { path: 'login',           component: LoginComponent },
    { path: 'members',           component: MembersComponent },
    { path: 'commpapers',           component: CommpaperComponent },
    { path: 'blog-detail',      component: BlogViewComponent },
    { path: 'all-news',      component: AllnewsComponent },
    { path: 'my-account',      component: MyAccountComponent }/* ,canActivate:[AuthGuard] */

    
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      scrollPositionRestoration: 'top',
      useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
