<header id="header" class="fixed-top ">
    <div class="container-fluid supScrnCont d-flex align-items-center justify-content-between">

   <!--    <h1 class="logo"><a href="index.html">Dewi</a></h1> -->
      <!-- Uncomment below if you prefer to use an image logo -->
     <a [routerLink]="['/home']" class="logo"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>

      <nav class="nav-menu d-none d-lg-block">
        <ul>
          <li [class]="activate==1?'active':''" (click)="activate=1"><a  [routerLink]="['/home']">الرئيسية</a></li>
          <li [class]="activate==2?'active':''" (click)="activate=2"><a class="scroll" href="#about">حول الغرفة</a></li>
        <!--   <li><a href="#services">تعاميم الغرفة</a></li> -->
          <li [class]="activate==3?'active':''" (click)="activate=3"><a [routerLink]="['/members']">أعضاء مجلس الإدارة</a></li>
          <li [class]="activate==4?'active':''" (click)="activate=4"><a [routerLink]="['/all-news']">آخر الأخبار</a></li>
         <!--  <li class="drop-down"><a href="">تعاميم الغرفة</a>
            <ul>
              <li><a [routerLink]="['/commpapers']">تعاميم واردة من اتحاد غرف التجارة</a></li>
              <!-- <li class="drop-down"><a href="#">Deep Drop Down</a>
                <ul>
                  <li><a href="#">Deep Drop Down 1</a></li>
                  <li><a href="#">Deep Drop Down 2</a></li>
                  <li><a href="#">Deep Drop Down 3</a></li>
                  <li><a href="#">Deep Drop Down 4</a></li>
                  <li><a href="#">Deep Drop Down 5</a></li>
                </ul>
              </li> -->
             <!--  <li><a href="#">Drop Down 2</a></li>
              <li><a href="#">Drop Down 3</a></li> -->
              <!-- <li><a [routerLink]="['/commpapers']">تعاميم صادرة عن غرفة تجارة و صناعة طرطوس</a></li>
            </ul>
          </li> --> 
          <li><a class="scroll" href="#contact">تواصل معنا</a></li>

        </ul>
      </nav><!-- .nav-menu -->

 <a [routerLink]="['/login']" *ngIf="auth.islogged == 0" class="get-started-btn scrollto">تسجيل الدخول</a> 
 <a (click)="logoutLogin()" *ngIf="auth.islogged == 1" class="get-started-btn scrollto">تسجيل الخروج</a> 
     

    </div>

    <div class="container-fluid  resCont d-flex align-items-center justify-content-between">

      <!--    <h1 class="logo"><a href="index.html">Dewi</a></h1> -->
         <!-- Uncomment below if you prefer to use an image logo -->
   
        <nav class="navbar navbar-expand-lg navbar-dark pt-3">
         <a  class="logo navbar-brand"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
        
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
              <li class="nav-item active">
                <a [routerLink]="['/home']" class="nav-link">الرئيسية <span class="sr-only">(current)</span></a>
              </li>
              <li class="nav-item">
                <a  class="nav-link">حول الغرفة</a>
                <a [routerLink]="['/login']" *ngIf="auth.islogged == 0" class="get-started-btn scrollto">تسجيل الدخول</a> 
                <a (click)="logoutLogin()" *ngIf="auth.islogged == 1" class="get-started-btn scrollto">تسجيل الخروج</a> 
              </li>
             <!--  <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  تعاميم الغرفة
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a class="dropdown-item" href="#">تعاميم واردة من اتحاد غرف التجارة</a>
                  <a class="dropdown-item" href="#">تعاميم صادرة عن غرفة تجارة و صناعة طرطوس</a>
                   <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#">Something else here</a> 
                </div>
              </li> -->
            </ul>
          </div>
        </nav>
       </div>
  </header>