import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'app/auth/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NewsService } from 'app/services/news.service';
import { UrlService } from 'app/services/url.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  _imgUrl;
  mydata;
  lastpay;
  constructor(private router: Router,private fb: FormBuilder,private auth: AuthService, private _router :Router,private _getallNews:NewsService,private urlapi :UrlService) {
    this._imgUrl=urlapi.imgUrl;
   }

  ngOnInit(): void {
    this.me();
  }

  me(){
    this._getallNews.getme()
        .subscribe(data => {this.mydata = data.data.result[0].data/* ,console.log(data.data.result[0].data) */,this.lastpay=data.data.result[1].lastPay},
        error => {
          if (error instanceof HttpErrorResponse){
            if(error.status === 401){
          this.router.navigate(['/home']) }
          else if(error.status === 0){
          }
       /*    console.log(error); */
        }
        });
  }
}
