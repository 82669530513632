import { Injectable } from '@angular/core';
import { UrlService } from '../services/url.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
private _login_Url:string;
_url :string;
islogged;
loggedUser;
ActiveUser;
loginChanged =new BehaviorSubject(null);
  constructor(private http :HttpClient, private urlapi :UrlService, private _router : Router) { 
    this._login_Url=urlapi.url;
  }

  loginUser(user :any) :Observable<any>{
    console.log('uss',user);
    return this.http.post(`${this._login_Url}auth/v1/webRegister`,JSON.stringify(user),{
      headers:new HttpHeaders({
        'Accept':'application/json',
        'Content-Type':'application/json'
      })
    });
    }

loggedIn(){
  return !!localStorage.getItem('commercwtoken')
}

getToken(){
  return localStorage.getItem('commercwtoken')
}

logoutUser(){
  localStorage.removeItem('commercwtoken');
  localStorage.removeItem('commercwloggedUname');
  localStorage.removeItem('commercwloggedUActive');
  this._router.navigate(['/home']);
}

checkLog(){
  this.loginChanged.next(this.islogged);
 this.loggedUser=localStorage.getItem('commercwloggedUname');
 this.ActiveUser=localStorage.getItem('commercwloggedUActive');
 if(localStorage.getItem('commercwloggedUname') != undefined){
   if(localStorage.getItem('commercwloggedUname') != undefined){
     this.islogged=1;
   }
 }else{
   this.islogged=0;
 }

}

}