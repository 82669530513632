<div class="wrapper">
    <div class="page-header" style="background-image: url('assets/img/DF.jpg');">
        <div class="filter"></div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-sm-6 mr-auto ml-auto">
                      <div class="card card-register">
                          <h3 class="title">تسجيل الدخول</h3>
                        <!--   <div class="social-line text-center">
                              <a href="#pablo" class="btn btn-neutral btn-facebook btn-just-icon">
                                  <i class="fa fa-facebook-square"></i>
                              </a>
                              <a href="#pablo" class="btn btn-neutral btn-google btn-just-icon">
                                  <i class="fa fa-google-plus"></i>
                              </a>
                              <a href="#pablo" class="btn btn-neutral btn-twitter btn-just-icon">
                                  <i class="fa fa-twitter"></i>
                              </a>
                          </div> -->
                          <form class="register-form" [formGroup]='loginForm'>
                              <label>اسم المستخدم</label>
                              <div class="input-group form-group-no-border" [ngClass]="{'input-group-focus':focus===true}">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">
                                      <i class="fa fa-user"></i>
                                  </span>
                                </div>

                                  <input type="text" name="username" #username formControlName="username" class="form-control" placeholder="اسم المستخدم" (focus)="focus=true" (blur)="focus=false" required ngModule >
                              </div>
                              <label>رقم الإضبارة</label>
                              <div class="input-group form-group-no-border" [ngClass]="{'input-group-focus':focus1===true}">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">
                                      <i class="fa fa-bookmark"></i>
                                  </span>
                                </div>

                                  <input type="text" name="dataid" #dataid formControlName="dataid"   class="form-control" placeholder="رقم الإضبارة" (focus)="focus1=true" (blur)="focus1=false"  required ngModule>
                              </div>
                              <label>السجل التجاري</label>
                              <div class="input-group form-group-no-border" [ngClass]="{'input-group-focus':focus2===true}">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">
                                      <i class="fa fa-address-book"></i>
                                  </span>
                                </div>

                                  <input type="text" name="recordno" #recordno formControlName="recordno"  class="form-control" placeholder="السجل التجاري" (focus)="focus2=true" (blur)="focus2=false"  required ngModule>
                              </div>
                            
                              <label>كلمة المرور</label>
                              <div class="input-group form-group-no-border" [ngClass]="{'input-group-focus':focus3===true}">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">
                                      <i class="nc-icon nc-key-25"></i>
                                  </span>
                                </div>
                                  <input type="password" name="password" #password formControlName="password" class="form-control" placeholder="كلمة مرور" (focus)="focus3=true" (blur)="focus3=false"  required ngModule>
                              </div> 
                              <div class="form-group-no-border">
                                <div class="input-group-prepend">
                            <select class="custom-select mt-3" name="role" #role formControlName="role" [ngModel]="selectedItem" ngModule>
                                <option value="10" selected >تاجر</option>
                                <option value="11"  >شركة</option>
                              </select>
                              </div></div>
                              <button class="btn btn-primary btn-block btn-round"  [disabled]="loginForm.invalid || loading==1" (click)="login()">تسجيل</button>
                          </form>
                          <div class="col-md-12 text-center spinner" *ngIf="loading==1"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                           <div class="forgot">
                              <a *ngIf="message != null" [style.color]="succsflag==1?'green':'red'" class="btn btn-link btn-danger">{{message}}</a>
                          </div> 
                      </div>
                    </div>
                </div>
            </div>
    </div>
</div>
