import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NewsService } from 'app/services/news.service';
import { UrlService } from 'app/services/url.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss']
})
export class MembersComponent implements OnInit {
  //settings
  settings;
  loading;
  condsettings=new Array();
  membersettings=new Array();
  gensettings=new Array();
  servsettings=new Array();
  _imgUrl;
  child=[];
  parent=[];
  nestedmems=[];
  constructor(private renderer : Renderer2,private route: ActivatedRoute,private router: Router ,private _getallNews:NewsService,private urlapi :UrlService) {
    this._imgUrl=urlapi.imgUrl;}
members;
  ngOnInit(): void {
    this.members=JSON.parse(localStorage.getItem('membersettings'));
    if(this.members == undefined || this.members == null){
    this.getsettings();}
/*     console.log(this.members); */
  }

  getsettings(){
    let limit = 1000;
    this._getallNews.getSettings()
    .subscribe(data => {this.settings = data.data,/* console.log('sett',data.data), */this.filterset(this.settings.settings)},
    error => {
      if (error instanceof HttpErrorResponse){
        if(error.status === 401){
      this.router.navigate(['/index']) }
      else if(error.status === 0){
        this.loading=0;
      }
      /* console.log(error); */
    }
    });
}
filterset(data){
  for(let i in data){
    if(data[i].type == 0){
this.gensettings.push(data[i]);
    }else if(data[i].type == 1) {
      this.membersettings.push(data[i]);
    }else if(data[i].type == 2) {
      this.servsettings.push(data[i]);
    }
    else if(data[i].type == 3) {
      this.condsettings.push(data[i]);
    }

  }
 /*  console.log('mem',this.membersettings); */
  localStorage.setItem('servsettings',JSON.stringify(this.servsettings));
  localStorage.setItem('membersettings',JSON.stringify(this.membersettings));
  localStorage.setItem('gensettings',JSON.stringify(this.gensettings));
  localStorage.setItem('condsettings',JSON.stringify(this.condsettings));
}


/* nesting(data){
for(let i=1; i<data.length ;i++){
  for(let j=1; j<data.length ;j++){
   if(+data.length%4 == 0){
    this.parent.push(data[i]);
  }
}
}  */

/* this.nestedmems.push(this.parent);
console.log('nestedmems',this.nestedmems);
} */

}
