<div class="page-header" data-parallax="true" style="background-image: url('assets/img/industry.jpg');">
    <div class="filter"></div>
    <div class="container">
     <div class="motto text-center">
            <h3>تعاميم الغرفة</h3>
            <br />
        </div>  
    </div>
</div>
<main id="main">
<section id="blog-details" class="blog-details mt-0">
<div class="container p-5 text-right">
 
<div class="page-wrapper">
    <div class="content"> 
      <div class=" section-title">
    <h2>تعاميم الغرفة</h2>
    <p>تعاميم واردة من اتحاد غرفة التجارة</p>
  </div>
        <div class="row">
            <div class="col-md-3 col-lg-4">
                <div class="detailscard card" style="width: 18rem;">
                    <div class="card-body">
                      <h2><span class="badge badge-secondary">1</span></h2>
                      <h5 class="card-title">..طلب كتاب هيئة دعم و تنمية</h5>
                      <a href="#" class="card-link">تحميل الملف</a>
                    </div>
                  </div>
            </div>
            <div class="col-md-3  col-lg-4">
              <div class="detailscard card" style="width: 18rem;">
                  <div class="card-body">
                    <h2><span class="badge badge-secondary">1</span></h2>
                    <h5 class="card-title">..طلب كتاب هيئة دعم و تنمية</h5>
                    <a href="#" class="card-link">تحميل الملف</a>
                  </div>
                </div>
          </div>
          <div class="col-md-3  col-lg-4">
            <div class="detailscard card" style="width: 18rem;">
                <div class="card-body">
                  <h2><span class="badge badge-secondary">1</span></h2>
                  <h5 class="card-title">..طلب كتاب هيئة دعم و تنمية</h5>
                  <a href="#" class="card-link">تحميل الملف</a>
                </div>
              </div>
        </div>
            </div>
            </div>
            <div class="content mt-5"> 
              <div class=" section-title">
            <h2>تعاميم الغرفة</h2>
            <p>تعاميم صادرة عن غرفة تجارة و صناعة طرطوس</p>
          </div>
                <div class="row">
                    <div class="col-md-3  col-lg-4">
                        <div class="detailscard card" style="width: 18rem;">
                            <div class="card-body">
                              <h2><span class="badge badge-secondary">1</span></h2>
                              <h5 class="card-title">..طلب كتاب هيئة دعم و تنمية</h5>
                              <a href="#" class="card-link">تحميل الملف</a>
                            </div>
                          </div>
                    </div>
                    <div class="col-md-3  col-lg-4">
                      <div class="detailscard card" style="width: 18rem;">
                          <div class="card-body">
                            <h2><span class="badge badge-secondary">1</span></h2>
                            <h5 class="card-title">..طلب كتاب هيئة دعم و تنمية</h5>
                            <a href="#" class="card-link">تحميل الملف</a>
                          </div>
                        </div>
                  </div>
                  <div class="col-md-3  col-lg-4">
                    <div class="detailscard card" style="width: 18rem;">
                        <div class="card-body">
                          <h2><span class="badge badge-secondary">1</span></h2>
                          <h5 class="card-title">..طلب كتاب هيئة دعم و تنمية</h5>
                          <a href="#" class="card-link">تحميل الملف</a>
                        </div>
                      </div>
                </div>
                    </div>
                    </div>
</div>
</div></section></main>
