import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'app/auth/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
export class User{
  username;
  data_id;
  record_no;
  role;
  password;
  
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  focus1;
  focus2;
  focus3;
  focus;
  focus4;
  focus5;
  focus6;
  focus7;
  focus8;
  focus9;
  focus10;
  user:User;
  message:string;
  succsflag:number;
  errMessage:string;
  loading:number;
  selectedItem;
  constructor(private router: Router,private fb: FormBuilder,private auth: AuthService, private _router :Router) { }
  loginForm = this.fb.group({
    username: ['',Validators.required],
    dataid: ['',Validators.required],
    recordno : ['',Validators.required],
    role: ['',Validators.required],
    password:['',Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]
        });
  ngOnInit(): void {
  }

  login(){
    this.loading=1;
    this.user= new User();
    this.user.username=this.loginForm.get('username').value;
    this.user.data_id=this.loginForm.get('dataid').value;
    this.user.record_no=this.loginForm.get('recordno').value;
    this.user.role=this.loginForm.get('role').value;
    this.user.password=this.loginForm.get('password').value;
   /*  console.log(JSON.stringify(this.user)); */
    this.auth.loginUser(this.user)
    .subscribe(
      res => {
        this.loading=0,
      /*   console.log(res.data.token,'bn',res.data.result[0].data.name), */
        this.succsflag=1,
        this.message="تم تسجيل الدخول بنجاح",
        localStorage.setItem('commercwtoken',res.data.token),
      /*   localStorage.setItem('user',JSON.stringify(res.data.result)), */
        localStorage.setItem('commercwloggedUname',res.data.result[0].data.name),
        localStorage.setItem('commercwloggedUActive',res.data.status),
        this.auth.checkLog(),
        this._router.navigate(['/my-account'])
      },
      error => {
        if (error instanceof HttpErrorResponse){
        if(error.status === 400){
          this.succsflag=2;
          this.message="الاسم محجوز سابقاً";
           }
           else if(error.status === 401){
            this.succsflag=3;
            this.message="خطا في كلمة السر";
          }
      else if(error.status === 0){
        this.succsflag=3;
      }
      else if(error.status === 404){
        this.message="لا يوجد سجلات مطابقة للمعلومات المدخلة ";
      }
    /*   console.log(error); */
      }this.loading=0;
     this.auth.islogged=0; 
      ;
    })
    }

    

}
