import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { AuthService } from 'app/auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  loggeduser:string;
  loggedUser;
  ActiveUser;
  islogged;
  activate=1;
  constructor(private router: Router,private fb: FormBuilder,public auth: AuthService, private _router :Router) { }

  ngOnInit(): void {
  this.loggedusr();
  this.islogged = this.auth.loginChanged.subscribe((value)=>{this.islogged/* ,console.log(this.islogged) */});

  
  }

    scrollToTop() {
      (function smoothscroll() {
          var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
          if (currentScroll > 0) {
              window.requestAnimationFrame(smoothscroll);
              window.scrollTo(0, currentScroll - (currentScroll / 8));
          }
      })();
  }

 /*  scrollToElement(): void {
   
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
          e.preventDefault();
          document.querySelector(this.getAttribute('href')).scrollIntoView({
              behavior: 'smooth'
          });
      });
  });
  } */

  loggedusr():string{
    if(localStorage.getItem('commercwloggedUname') != undefined){
      this.loggeduser=localStorage.getItem('commercwloggedUname');
  }
  this.loggedUser=localStorage.getItem('commercwloggedUname');
this.ActiveUser=localStorage.getItem('commercwloggedUActive');

if(localStorage.getItem('commercwloggedUname') != undefined){
  this.auth.islogged=1;
  this.loggeduser=localStorage.getItem('commercwloggedUname');
}else{
  this.auth.islogged=0;
}
return this.loggeduser;
  }

  logoutLogin(){
      this.auth.logoutUser();
      this.auth.islogged =0;
      this.auth.checkLog();
    }

}
