import { Component, OnInit, Renderer2 } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { UrlService } from 'app/services/url.service';
import { NewsService } from 'app/services/news.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import * as _rollupMoment from 'moment';
declare var $: any;
const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-blog-view',
  templateUrl: './blog-view.component.html',
  styleUrls: ['./blog-view.component.scss']
})
export class BlogViewComponent implements OnInit {
  _imgUrl;
  item;
  tages;
  loading;
  news=[];
  mainews=[];
  date: {year: number, month: number};
  model: NgbDateStruct;
  constructor(private renderer : Renderer2,private route: ActivatedRoute,private router: Router ,private _getallNews:NewsService,private urlapi :UrlService) {
    this._imgUrl=urlapi.imgUrl;
}

  ngOnInit(): void {
    if(this._getallNews.getItem() != undefined){
      this.item = this._getallNews.getItem();
      localStorage.setItem('storednews',JSON.stringify(this.item));
      this.tags(this.item.tags);
       }else{
        /*  this.router.navigate(['/categories']).then( (e) => {
          if (e) {
            console.log("Navigation is successful!");
          } else {
            console.log("Navigation has failed!");
          }
        }); */
        this.item=JSON.parse(localStorage.getItem('storednews'));
  /*       console.log( this.item); */
        this.tags(this.item.tags);
        }
        this.getmainews();
  }

  itemDetails(item){
    this._getallNews.setItem(item);
    let currentUrl = this.router.url;
    
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate(['/blog-detail'])/* .then(() => { 
        window.location.reload(); }); */
      });
   
   }

   getType(type){
    if(type == 0){
      return "عام";
    }else if(type == 1){
      return "فعاليات";
    }else if(type == 2){
  return "زيارات";
    }else{
      return "اجتماعات"
    }
  }

  getDate(Cdate:string):string{
    var currentdate = new Date();
    var date =  new Date(Cdate).toLocaleDateString('en-GB');
    return date;
  }

  

  allNews(foo){
    this.router.navigate(['/all-news'],{queryParams:{foo: foo}}).then( (e) => {
      if (e) {
        console.log("Navigation is successful!");
      } else {
        console.log("Navigation has failed!");
      }
    });
  }
  

  showDate(Cdate:string):string{
    var currentdate = new Date();
    var date =  new Date(Cdate).toLocaleString();
    return date;
  }

  formatutc(date_obb){
    let date_ob = new Date(date_obb);

// adjust 0 before single digit date
let date = ("0" + date_ob.getDate()).slice(-2);

// current month
let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

// current year
let year = date_ob.getFullYear();

// current hours
let hours = date_ob.getHours();

// current minutes
let minutes = date_ob.getMinutes();

// current seconds
let seconds = date_ob.getSeconds();

// prints date & time in YYYY-MM-DD HH:MM:SS format
return(year + "-" + month + "-" + date );/* + " " + hours + ":" + minutes + ":" + seconds */
  }

  getallnews(){
    let limit = 1000;
    this._getallNews.getNewsData(limit)
    .subscribe(data => {this.news = data.data[0]/* ,console.log(data.data[0]) */},
    error => {
      if (error instanceof HttpErrorResponse){
        if(error.status === 401){
      this.router.navigate(['/index']) }
      else if(error.status === 0){
        this.loading=0;
      }
      /* console.log(error); */
    }
    });
}
getmainews(){
    let limit = 5;
    this._getallNews.getMainNews(limit)
    .subscribe(data => {this.mainews = data.data[0]/* ,console.log(data.data[0]) */},
    error => {
      if (error instanceof HttpErrorResponse){
        if(error.status === 401){
      this.router.navigate(['/index']) }
      else if(error.status === 0){
        this.loading=0;
      }
    /*   console.log(error); */
    }
    });
}

tags(str1){
  let str =str1;
 this.tages=str.split(/[ ,]+/); ;
}





}
