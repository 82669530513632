import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-commpaper',
  templateUrl: './commpaper.component.html',
  styleUrls: ['./commpaper.component.scss']
})
export class CommpaperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
