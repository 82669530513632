import { Component, OnInit, Renderer2 } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { UrlService } from 'app/services/url.service';
import { NewsService } from 'app/services/news.service';
import { HttpErrorResponse } from '@angular/common/http';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize } from 'ng-gallery';

import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import * as _rollupMoment from 'moment';
declare var $: any;


const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};


@Component({
    selector: 'app-components',
    templateUrl: './components.component.html',
    styles: [`
    ngb-progressbar {
        margin-top: 5rem;
    }
    `],
    styleUrls: ['./components.component.scss']
})

export class ComponentsComponent implements OnInit {

  customOptions: OwlOptions = {
    stagePadding: 20,
    loop:true,
    autoplay:true,
    margin:10,
    nav:true,
    dots:false,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:3
        },
        1000:{
            items:3
        }
    }
  }

  customOptions2: OwlOptions = {
    stagePadding: 20,
    loop:true,
    autoplay:true,
    margin:30,
    nav:true,
    items:1,
    dots:false,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:1
        },
        1000:{
            items:1
        }
    }
  }

  genset;
  membset;
  servset;
 condset;
 slides=[];
    test : Date = new Date();
    page = 4;
    page1 = 5;
    focus;
    focus1;
    focus2;
    loading;
    news=[];
    mainews=[];
    activenews=[];
    settings;
   _imgUrl;

   //settings
   condsettings=new Array();
   membersettings=new Array();
   gensettings=new Array();
   servsettings=new Array();
    //paging
  current_page;
  next;
  last_page;
  prev_page_url;
   gallery=[];
    date: {year: number, month: number};
    model: NgbDateStruct;
    constructor( private renderer : Renderer2,private route: ActivatedRoute,private router: Router ,private _getallNews:NewsService,private urlapi :UrlService) {
        this._imgUrl=urlapi.imgUrl;
    }
    isWeekend(date: NgbDateStruct) {
        const d = new Date(date.year, date.month - 1, date.day);
        return d.getDay() === 0 || d.getDay() === 6;
    }

    isDisabled(date: NgbDateStruct, current: {month: number}) {
        return date.month !== current.month;
    }

    ngOnInit() {
       
        let input_group_focus = document.getElementsByClassName('form-control');
        let input_group = document.getElementsByClassName('input-group');
        for (let i = 0; i < input_group.length; i++) {
            input_group[i].children[0].addEventListener('focus', function (){
                input_group[i].classList.add('input-group-focus');
            });
            input_group[i].children[0].addEventListener('blur', function (){
                input_group[i].classList.remove('input-group-focus');
            });
        }

        this. getmainews();
        this. getallnews();
        this. getactivnews();
        this. getsettings();
      /*   this. getGallery(); */

 /*        $('#testimonials .owl-carousel').owlCarousel({
            stagePadding: 20,
            loop:true,
            autoplay:true,
            margin:10,
            nav:true,
            responsive:{
                0:{
                    items:1
                },
                600:{
                    items:3
                },
                1000:{
                    items:3
                }
            }
        });
        $('#latestnews .owl-carousel').owlCarousel({
            stagePadding: 20,
            loop:true,
            autoplay:true,
            margin:10,
            nav:true,
            responsive:{
                0:{
                    items:1
                },
                600:{
                    items:1
                },
                1000:{
                    items:3
                }
            }
        });

    

        $("#varnews .owl-carousel").owlCarousel({
 
            navigation : true, // Show next and prev buttons
            loop:true,
            autoplay:true,
            slideSpeed : 300,
            paginationSpeed : 400,
       
            items : 1, 
            itemsDesktop : false,
            itemsDesktopSmall : false,
            itemsTablet: false,
            itemsMobile : false
       
        }); */
    }

    getallnews(){
        let limit = 1000;
        this._getallNews.getNewsData(limit)
        .subscribe(data => {this.news = data.data.data},/* ,console.log(data.data.data) */
        error => {
          if (error instanceof HttpErrorResponse){
            if(error.status === 401){
          this.router.navigate(['/index']) }
          else if(error.status === 0){
            this.loading=0;
          }
        /*   console.log(error); */
        }
        });
    }
    getactivnews(){
      let limit = 1000;
      this._getallNews.getNewsData(limit,null,1)
      .subscribe(data => {this.activenews = data.data.data/* ,console.log(data.data.data) */},
      error => {
        if (error instanceof HttpErrorResponse){
          if(error.status === 401){
        this.router.navigate(['/index']) }
        else if(error.status === 0){
          this.loading=0;
        }
        console.log(error);
      }
      });
    }
    getmainews(){
        let limit = 1000;
        this._getallNews.getMainNews(limit)
        .subscribe(data => {this.mainews = data.data[0]/* ,console.log(data.data[0]) */},
        error => {
          if (error instanceof HttpErrorResponse){
            if(error.status === 401){
          this.router.navigate(['/index']) }
          else if(error.status === 0){
            this.loading=0;
          }
         /*  console.log(error); */
        }
        });
    }

    getsettings(){
      let limit = 1000;
      this._getallNews.getSettings()
      .subscribe(data => {this.settings = data.data,/* console.log('sett',data.data) ,*/this.filterset(this.settings.settings)},
      error => {
        if (error instanceof HttpErrorResponse){
          if(error.status === 401){
        this.router.navigate(['/index']) }
        else if(error.status === 0){
          this.loading=0;
        }
    /*     console.log(error); */
      }
      });
  }
  filterset(data){
    for(let i in data){
      if(data[i].type == 0){
this.gensettings.push(data[i]);
      }else if(data[i].type == 1) {
        this.membersettings.push(data[i]);
      }else if(data[i].type == 2) {
        this.servsettings.push(data[i]);
      }
      else if(data[i].type == 3) {
        this.condsettings.push(data[i]);
      }
      else if(data[i].type == 4) {
        this.slides.push(data[i]);
       /*  console.log(this.slides); */
      }

    }
/*     console.log('gensettings',this.gensettings); */
    localStorage.setItem('servsettings',JSON.stringify(this.servsettings));
    localStorage.setItem('membersettings',JSON.stringify(this.membersettings));
    localStorage.setItem('gensettings',JSON.stringify(this.gensettings));
    localStorage.setItem('condsettings',JSON.stringify(this.condsettings));
    this.genset=JSON.parse(localStorage.getItem('gensettings'));
    this.membset=JSON.parse(localStorage.getItem('membersettings'));
    this.servset=JSON.parse(localStorage.getItem('servsettings'));
    this.condset=JSON.parse(localStorage.getItem('condsettings'));
  }

  getGallery(){
    let limit = 1000;
    this._getallNews.getGallery()
    .subscribe(data => {this.gallery = data.data,/* console.log(data) ,*/
      $('#testimonials .owl-carousel').owlCarousel({
        stagePadding: 20,
        loop:true,
        autoplay:true,
        margin:10,
        nav:true,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:3
            },
            1000:{
                items:3
            }
        }
    });
    $('#latestnews .owl-carousel').owlCarousel({
        stagePadding: 20,
        loop:true,
        autoplay:true,
        margin:10,
        nav:true,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:1
            },
            1000:{
                items:3
            }
        }
    });



    $("#varnews .owl-carousel").owlCarousel({

        navigation : true, // Show next and prev buttons
        loop:true,
        autoplay:true,
        slideSpeed : 300,
        paginationSpeed : 400,
   
        items : 1, 
        itemsDesktop : false,
        itemsDesktopSmall : false,
        itemsTablet: false,
        itemsMobile : false
   
    });
    
    
    },
    error => {
      if (error instanceof HttpErrorResponse){
        if(error.status === 401){
      this.router.navigate(['/index']) }
      else if(error.status === 0){
        this.loading=0;
      }
    /*   console.log(error); */
    }
    });
}

getType(type){
  if(type == 0){
    return "أخبار";
  }else if(type == 1){
    return "فعاليات";
  }else if(type == 2){
return "زيارات";
  }else{
   return "اجتماعات";
  }
}


getclass(type){
  if(type == 0){
    return "filter-news";
  }else if(type == 1){
    return "filter-act";
  }else if(type == 2){
return "filter-visit";
  }else{
    "filter-conf"
  }
}

    getDate(Cdate:string):string{
        var currentdate = new Date();
        var date =  new Date(Cdate).toLocaleDateString('en-GB');
        return date;
      }
    
      showDate(Cdate:string):string{
        var currentdate = new Date();
        var date =  new Date(Cdate).toLocaleString();
        return date;
      }

      formatutc(date_obb){
        let date_ob = new Date(date_obb);
    
    // adjust 0 before single digit date
    let date = ("0" + date_ob.getDate()).slice(-2);
    
    // current month
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    
    // current year
    let year = date_ob.getFullYear();
    
    // current hours
    let hours = date_ob.getHours();
    
    // current minutes
    let minutes = date_ob.getMinutes();
    
    // current seconds
    let seconds = date_ob.getSeconds();
    
    // prints date & time in YYYY-MM-DD HH:MM:SS format
    return(year + "-" + month + "-" + date );/* + " " + hours + ":" + minutes + ":" + seconds */
      }

      itemDetails(item){
        this._getallNews.setItem(item);
        let currentUrl = this.router.url;
        
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.router.navigate(['/blog-detail'])/* .then(() => { 
            window.location.reload(); }); */
          });
       
       }

       loadmorenews(){
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate(['/all-news'])/* .then(() => { 
          window.location.reload(); }); */
        });
       }

}
