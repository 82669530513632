import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlService {
 private _generalURL="https://app.tcci-sy.com/api/";
  private _URL="https://app.tcci-sy.com/api/";
  private _ImgURL="https://storage.tcci-sy.com/";
//private _URL="http://192.168.1.2/tartous-trade-backend/public/api/";
 //private _generalURL="http://192.168.1.2/tartous-trade-backend/public/api/";
 // private _ImgURL="http://192.168.1.2/tartous-trade-backend/storage/app/public/";

  constructor() { }
  get url(){
    return this._URL;
  }
  get imgUrl(){
    return this._ImgURL;
  }
 
  get gurl(){
    return this._generalURL;
  }
}