<div class="page-header" data-parallax="true" style="background-image: url('assets/img/DF.jpg');">
    <div class="filter"></div>
    <div class="container">
      <!--  <div class="motto text-center">
            <h3>فعاليات الغرفة</h3>
            <br />
        </div>  -->
    </div>
</div>
<main id="main">
<section id="blog-details" class="blog-details mt-0 pt-0">
<div class="container-fluid p-5">
<div class="page-wrapper">
    <div class="content">
        <div class="row">
            <div class="col-md-8">
                <div class="blog-view">
                      <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                          <li class="breadcrumb-item"><a class="mr-2" href="#">أخبار</a></li>
                          <li class="breadcrumb-item"><a class="mr-2" href="#"> {{getType(item.type)}}</a></li>
                          <li class="breadcrumb-item active" aria-current="page">&nbsp;&nbsp;{{item.title}}</li>
                        </ol>
                      </nav>
                    <article class="blog blog-single-post">
                        <h3 class="blog-title">{{item.title}}</h3>
                        <div class="blog-info clearfix">
                            <div class="post-left">
                                <ul>
                                    <li><a href="#."><i class="fa fa-calendar"></i> <span>{{formatutc(item.created_at)}}</span></a></li>
                                    <li><a href="#."><i class="fa fa-user-o"></i> <span>{{item.user.username}}</span></a></li>
                                </ul>
                            </div>
                            <div class="post-right"><a href="#."><i class="fa fa-eye"></i>{{item.seenCount}}</a></div>
                        </div>
                        <div class="blog-image">
                            <a href="#."><img alt="" src="{{_imgUrl}}{{item.media[0].id}}/{{item.media[0].file_name}}" class="img-fluid"></a>
                        </div>
                        <div class="blog-content" [innerHTML]="item.content">
                            
                        </div>
                    </article>
                    <div class="widget blog-share clearfix">
                        <h3>مشاركة البوست</h3>
                        <ul class="social-share">
                            <li><a href="#." title="Facebook"><i class="fa fa-facebook"></i></a></li>
                            <li><a href="#." title="Twitter"><i class="fa fa-twitter"></i></a></li>
                            <li><a href="#." title="Linkedin"><i class="fa fa-linkedin"></i></a></li>
                            <li><a href="#." title="Google Plus"><i class="fa fa-google-plus"></i></a></li>
                            <li><a href="#." title="Youtube"><i class="fa fa-youtube"></i></a></li>
                        </ul>
                    </div>
                 <!--    <div class="widget author-widget clearfix">
                        <h3>حول الكاتب</h3>
                        <div class="about-author">
                            <div class="about-author-img">
                                <div class="author-img-wrap">
                                    <img class="img-fluid rounded-circle" alt="" src="assets/img/user.jpg">
                                </div>
                            </div>
                            <div class="author-details">
                                <span class="blog-author-name">الأدمن</span>
                                <p>كاتب و محرر مقالات</p>
                            </div>
                        </div>
                    </div> -->
                   <!--  <div class="widget blog-comments clearfix">
                        <h3>Comments (3)</h3>
                        <ul class="comments-list">
                            <li>
                                <div class="comment">
                                    <div class="comment-author">
                                        <img class="avatar" alt="" src="assets/img/user.jpg">
                                    </div>
                                    <div class="comment-block">
                                        <span class="comment-by">
                                            <span class="blog-author-name">Diana Bailey</span>
                                        <span class="float-right">
                                                <span class="blog-reply"><a href="#."><i class="fa fa-reply"></i> Reply</a></span>
                                        </span>
                                        </span>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam viverra euismod odio, gravida pellentesque urna varius vitae, gravida pellentesque urna varius vitae. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam viverra euismod odio, gravida pellentesque urna varius vitae. Sed dui lorem, adipiscing in adipiscing et, interdum nec metus. Mauris ultricies, justo eu convallis placerat, felis enim ornare nisi, vitae mattis nulla ante id dui.</p>
                                        <span class="blog-date">December 6, 2017</span>
                                    </div>
                                </div>
                                <ul class="comments-list reply">
                                    <li>
                                        <div class="comment">
                                            <div class="comment-author">
                                                <img class="avatar" alt="" src="assets/img/user.jpg">
                                            </div>
                                            <div class="comment-block">
                                                <span class="comment-by">
                                                    <span class="blog-author-name">Henry Daniels</span>
                                                <span class="float-right">
                                                        <span class="blog-reply"><a href="#."><i class="fa fa-reply"></i> Reply</a></span>
                                                </span>
                                                </span>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam viverra euismod odio, gravida pellentesque urna varius vitae, gravida pellentesque urna varius vitae.</p>
                                                <span class="blog-date">December 6, 2017</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="comment">
                                            <div class="comment-author">
                                                <img class="avatar" alt="" src="assets/img/user.jpg">
                                            </div>
                                            <div class="comment-block">
                                                <span class="comment-by">
                                                    <span class="blog-author-name">Diana Bailey</span>
                                                    <span class="float-right">
                                                        <span class="blog-reply"> <a href="#."><i class="fa fa-reply"></i> Reply</a></span>
                                                    </span>
                                                </span>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam viverra euismod odio, gravida pellentesque urna varius vitae, gravida pellentesque urna varius vitae.</p>
                                                <span class="blog-date">December 7, 2017</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <div class="comment">
                                    <div class="comment-author">
                                        <img class="avatar" alt="" src="assets/img/user.jpg">
                                    </div>
                                    <div class="comment-block">
                                        <span class="comment-by">
                                            <span class="blog-author-name">Marie Wells</span>
                                            <span class="float-right">
                                                <span class="blog-reply"><a href="#."><i class="fa fa-reply"></i> Reply</a></span>
                                            </span>
                                        </span>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <span class="blog-date">December 11, 2017</span>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="comment">
                                    <div class="comment-author">
                                        <img class="avatar" alt="" src="assets/img/user.jpg">
                                    </div>
                                    <div class="comment-block">
                                        <span class="comment-by">
                                            <span class="blog-author-name">Pamela Curtis</span>
                                            <span class="float-right">
                                                <span class="blog-reply"><a href="#."><i class="fa fa-reply"></i> Reply</a></span>
                                            </span>
                                        </span>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <span class="blog-date">December 13, 2017</span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="widget new-comment clearfix">
                        <h3>Leave Comment</h3>
                        <form>
                            <div class="row">
                                <div class="col-sm-8">
                                    <div class="form-group">
                                        <label>Name <span class="text-red">*</span></label>
                                        <input type="text" class="form-control">
                                    </div>
                                    <div class="form-group">
                                        <label>Your email address <span class="text-red">*</span></label>
                                        <input type="email" class="form-control">
                                    </div>
                                    <div class="form-group">
                                        <label>Comments</label>
                                        <textarea rows="4" class="form-control"></textarea>
                                    </div>
                                    <div class="comment-submit">
                                        <input type="submit" value="Submit" class="btn">
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div> -->
                </div>
            </div>
            <aside class="col-md-4">
               <!--  <div class="widget search-widget">
                    <h5>Blog Search</h5>
                    <form class="search-form">
                        <div class="input-group">
                            <input type="text" placeholder="Search..." class="form-control">
                            <div class="input-group-append">
                                <button type="submit" class="btn btn-primary"><i class="fa fa-search"></i></button>
                            </div>
                        </div>
                    </form>
                </div> -->
                <div class="widget post-widget">
                    <h5>آخر الأخبار</h5>
                    <ul class="latest-posts">
                        <li class="cc" *ngFor="let mainews of mainews" (click)="itemDetails(mainews)">
                            <div class="post-thumb">
                                <a >
                                    <img class="img-fluid" src="{{_imgUrl}}{{mainews.media[0].id}}/{{mainews.media[0].file_name}}" alt="">
                                </a>
                            </div>
                            <div class="post-info">
                                <h4>
                                    <a>{{mainews.title}}</a>
                                </h4>
                                <p><i aria-hidden="true" class="fa fa-calendar"></i>&nbsp;&nbsp;{{formatutc(mainews.updated_at)}}</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="widget category-widget">
                    <h5>تصنيفات الأخبار</h5>
                    <ul class="categories">
                        <li class="cc"><a (click)="allNews(0)"><i class="fa fa-long-arrow-right"></i>عام</a></li>
                        <li class="cc"><a (click)="allNews(1)"><i class="fa fa-long-arrow-right"></i>فعاليات</a></li>
                        <li class="cc"><a (click)="allNews(2)"><i class="fa fa-long-arrow-right"></i>زيارات</a></li>
                        <li class="cc"><a (click)="allNews(3)"><i class="fa fa-long-arrow-right"></i>اجتماعات</a></li>
                    </ul>
                </div>
                <div class="widget tags-widget">
                    <h5>تاغ</h5>
                    <ul class="tags">
                        <li *ngFor="let tag of tages"><a href="#." class="tag">{{tag}}</a></li>
                    </ul>
                </div>
            </aside>
        </div>
    </div>
    </div>
</div>
</section></main>
<!-- <div id="preloader"></div> -->