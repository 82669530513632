import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree ,Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild{
  
  constructor(private _authService :AuthService, private _router : Router){

  }
  
  canActivate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean{
   if(this._authService.loggedIn()){
    console.log(this._authService.loggedIn())
     return true
   }else {
     console.log(this._authService.loggedIn())
     this._router.navigate(['/login'])
     return false
   }
  }
  canActivateChild(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean{
    return this.canActivate(route, state)
   }
}
