import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlService } from './url.service';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  _url :string;
  itm:any;
  constructor(private _http : HttpClient, private urlapi :UrlService, private route: ActivatedRoute) { 
    this._url=urlapi.gurl;
    this.route.queryParams.subscribe(x => this.getNewsData(x.page || 1));
  }
  getNewsData(limit ?:number,page ?:number,type?:number): Observable<any>{
    return this._http.get<any>(`${this._url}news/v1/all?limit=${limit}&page=${page}&type=${type}`);
  }
  getMainNews(limit ?:number,page ?:number): Observable<any>{
    return this._http.get<any>(`${this._url}news/v1/main?limit=${limit}&page=${page}`);
  }
/*   getNewsById(catid): Observable<any>{
    return this._http.get<any>(`${this._url}api/client/v1/categoryById/${catid}`,);
  }
   */

  getSettings(): Observable<any>{
    return this._http.get<any>(`${this._url}auth/v1/mainPage`);
  }

  getGallery(limit ?:number,type ?:number): Observable<any>{
    return this._http.get<any>(`${this._url}news/v1/gallery?limit=50`);
  }

  setItem(itm:any){
    this.itm=itm;
   }

  getItem():any{
   return this.itm;
  }

  getme(){
    return this._http.get<any>(`${this._url}auth/v1/me`);
  }
}
